/**
 *  Read data from Local Storage by key
 *
 * @param {string} key name of the Local storage item key
 * @return {Array} the data saved in Local Storage
 */
export const readLocalStorage = (key = null) => {
	if (!process.client) {
		return null;
	}

	if (typeof key === "string" && key.length > 0) {
		return JSON.parse(localStorage.getItem(key)) || [];
	}
	return null;
};

/**
 *  Write data into Local Storage by key
 *
 * @param {string} key name of the Local storage item key
 * @param {Array} data data want to save in the Local Storage
 * @return {Array} the data saved in Local Storage
 */
export const writeLocalStorage = (key = null, data = []) => {
	if (!process.client || !navigator?.cookieEnabled) {
		return [];
	}
	if (typeof key === "string" && key.length > 0 && data.length) {
		localStorage.setItem(key, JSON.stringify(data));
	}
	return readLocalStorage(key);
};
