import { MENU_ITEMS, LOGO_TYPES } from "@consts";

export default {
	name: "TheMobileNavigation",
	data() {
		return {
			menuData: [],
			externalSites: [
				{ type: LOGO_TYPES.WS, label: "Wordscapes Answers and Cheat" },
				{ type: LOGO_TYPES.WFW_ES, label: "Spanish WordFinder" },
				{ type: LOGO_TYPES.YDR, label: "YourDictionary" },
			],
		};
	},
	computed: {
		topIndent() {
			const isPersistentSearch =
				this.$store.getters["navigation/isPersistentSearch"];
			const extraMargin = isPersistentSearch ? 8 : 0;
			return (
				this.$store.state.window.topAdHeight +
				extraMargin +
				this.$store.getters["window/headerHeight"]
			);
		},
		mobileMenuIsOpen() {
			return this.$store.state.navigation.mobileMenuIsOpen;
		},
	},
	methods: {
		closeSubMenus() {
			this.menuData.forEach((item) => {
				if (typeof item.submenuIsOpen === "boolean") {
					item.submenuIsOpen = false;
				}
			});
		},
		toggleMobileMenu() {
			this.$store.commit("navigation/toggleMobileMenu");
			if (!this.mobileMenuIsOpen) {
				this.closeSubMenus();
			}
		},
		onLinkClick(entry) {
			this.dispatchGaEvent(entry);

			this.$store.commit("navigation/toggleMobileMenu", false);
			this.closeSubMenus();
		},
		onButtonClick(entry) {
			if (typeof entry.submenuIsOpen === "boolean") {
				entry.submenuIsOpen = !entry.submenuIsOpen;
			}
			this.dispatchGaEvent(entry);
		},
		dispatchGaEvent(entry) {
			const eventCategory = "Header";
			const eventLabel = entry.labelDictionaryKey;
			const eventActionTranslation =
				entry.gaEventAction || this.$t(entry.labelDictionaryKey);
			const eventAction = `Click on ${eventActionTranslation} navigation element`;
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory,
				eventAction,
				eventLabel,
			});
		},
	},
	created() {
		const reduceCallback = (acc, item) => {
			if (item.desktopOnly === true) {
				return acc;
			}

			if (Array.isArray(item.submenu)) {
				item.submenu = item.submenu.reduce(reduceCallback, []);
			}

			acc.push({ ...item });
			return acc;
		};

		this.menuData = MENU_ITEMS.reduce(reduceCallback, []);
	},
	watch: {
		mobileMenuIsOpen(value) {
			if (!value) {
				this.closeSubMenus();
			}
		},
	},
};
