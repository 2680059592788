import Vue from "vue";

import device from "./device";
import featureToggling from "./feature-toggling";
import rendering from "./rendering";
import pageCheck from "./page-check";

const mixins = {
	device,
	featureToggling,
	rendering,
	pageCheck,
};

Object.entries(mixins).forEach(([key, mixin]) => {
	const mixinKey = `__wf_mixin_${key}__`;

	if (!Vue[mixinKey]) {
		Vue[mixinKey] = true;
		Vue.mixin(mixin);
	}
});
