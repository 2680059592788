import { mapActions, mapState } from "vuex";
import { SweetModal } from "sweet-modal-vue";
import { NEWSLETTERS } from "@/common/consts";

export default {
	data() {
		return {
			NEWSLETTERS,
		};
	},
	watch: {
		isSubscriptionDialogOpen: "openSubscriptionDialog",
	},
	components: {
		SweetModal,
	},
	computed: {
		...mapState({
			isSubscriptionDialogOpen: (state) =>
				state.account.isSubscriptionDialogOpen,
		}),
		userData() {
			return this.$store.state.account.user;
		},
		userName() {
			return this.userData.data?.displayName;
		},
		userNewsletterSubscription() {
			return this.userData.newslettersSubscription;
		},
	},
	methods: {
		...mapActions("account", ["NEWSLETTER_SUBSCRIPTION_ACTIVATION"]),
		onSubscriptionAction(value) {
			const params = {
				status: value,
				website: NEWSLETTERS.WEBSITE,
			};
			this.NEWSLETTER_SUBSCRIPTION_ACTIVATION(params);
			const eventValues = {
				[NEWSLETTERS.ON]: {
					action: "Subscribe",
					label: "NewsletterSubscribeButtonClicked",
					id: 133,
				},
				[NEWSLETTERS.OFF]: {
					action: "No Thanks",
					label: "NewsletterNoThanksButtonClicked",
					id: 134,
				},
			};
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Newsletters",
				eventAction: `Click on Newsletter ${eventValues[value].action} Button`,
				eventLabel: eventValues[value].label,
				eventID: eventValues[value].id,
			});

			this.$refs.subscriptionModal.close();
		},
		openSubscriptionDialog(val) {
			if (val) {
				this.$refs.subscriptionModal.open();
				this.$store.dispatch("tracking/trackEvent", {
					eventCategory: "Newsletters",
					eventAction: "Newsletter subscription popup is shown",
					eventLabel: "NewsletterPopUpShown",
					eventID: 132,
				});
			}
		},
		closeSubscriptionDialog() {
			if (this.userNewsletterSubscription !== null) {
				return;
			}
			this.$store.commit("account/SET_SUBSCRIPTION_DIALOG_STATE", false);
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Newsletters",
				eventAction: "Newsletter subscription popup dismissed",
				eventLabel: "NewsletterPopUpDismissed",
				eventID: 135,
			});
		},
	},
};
