export const BANNED_WORDS = {
	abos: 1,
	anal: 1,
	anilingus: 1,
	anus: 1,
	asshole: 1,
	asswhore: 1,
	asswhores: 1,
	asswipe: 1,
	asswipes: 1,
	autofellatio: 1,
	bitchinesses: 1,
	blowjob: 1,
	blowjobs: 1,
	bulldyke: 1,
	chink: 1,
	chinks: 1,
	chinky: 1,
	clit: 1,
	clits: 1,
	cocksucker: 1,
	cocksuckers: 1,
	cocksucking: 1,
	cogway: 1,
	coolies: 1,
	cooly: 1,
	cum: 1,
	cunnilinction: 1,
	cunnilinctor: 1,
	cunnilinctors: 1,
	cunnilinctus: 1,
	cunnilingual: 1,
	cunnilinguism: 1,
	cunnilinguist: 1,
	cunnilinguistic: 1,
	cunnilinguists: 1,
	cunnilingus: 1,
	cunny: 1,
	cunt: 1,
	cunts: 1,
	cunty: 1,
	darkey: 1,
	darkies: 1,
	deepthroat: 1,
	deepthroating: 1,
	dick: 1,
	dicks: 1,
	dikey: 1,
	dildo: 1,
	dildoe: 1,
	dildoes: 1,
	dildos: 1,
	fag: 1,
	fagged: 1,
	fagging: 1,
	faggot: 1,
	faggoted: 1,
	faggoting: 1,
	faggotings: 1,
	faggotries: 1,
	faggotry: 1,
	faggots: 1,
	faggoty: 1,
	faggy: 1,
	fagin: 1,
	fagins: 1,
	fagot: 1,
	fagoted: 1,
	fagoter: 1,
	fagoters: 1,
	fagoting: 1,
	fagotings: 1,
	fagots: 1,
	fags: 1,
	fellatio: 1,
	fellation: 1,
	fellatios: 1,
	fellator: 1,
	fellators: 1,
	fellatrice: 1,
	fellatrices: 1,
	fellatrix: 1,
	fuck: 1,
	fuckbuddy: 1,
	fucked: 1,
	fucker: 1,
	fuckers: 1,
	fuckhead: 1,
	fucking: 1,
	fucks: 1,
	fuckup: 1,
	fuckups: 1,
	gyp: 1,
	gypo: 1,
	gyps: 1,
	gypsy: 1,
	handjob: 1,
	handjobs: 1,
	hodaddy: 1,
	honkeys: 1,
	horny: 1,
	jew: 1,
	jewish: 1,
	jews: 1,
	jigaboo: 1,
	jigaboos: 1,
	kerchoo: 1,
	kike: 1,
	kikes: 1,
	kink: 1,
	kinkily: 1,
	kinks: 1,
	kinky: 1,
	kkk: 1,
	kkker: 1,
	kkkers: 1,
	knacker: 1,
	knackers: 1,
	kyke: 1,
	lesbo: 1,
	micks: 1,
	milf: 1,
	milfs: 1,
	mongoloid: 1,
	mongoloids: 1,
	motherfucker: 1,
	motherfuckers: 1,
	motherfucking: 1,
	nazi: 1,
	nazification: 1,
	nazifications: 1,
	nazified: 1,
	nazifies: 1,
	nazify: 1,
	nazifying: 1,
	nazis: 1,
	negroid: 1,
	negroids: 1,
	niggardlier: 1,
	niggardliest: 1,
	nigged: 1,
	nigger: 1,
	niggerier: 1,
	niggeriest: 1,
	niggers: 1,
	niggle: 1,
	niggled: 1,
	niggler: 1,
	nigglers: 1,
	niggles: 1,
	nongays: 1,
	porn: 1,
	porno: 1,
	pornography: 1,
	pussies: 1,
	pussy: 1,
	puta: 1,
	putas: 1,
	rape: 1,
	raped: 1,
	raper: 1,
	rapers: 1,
	rapes: 1,
	sheenie: 1,
	shithead: 1,
	shitheads: 1,
	slut: 1,
	sluts: 1,
	sluttier: 1,
	sluttiest: 1,
	sluttish: 1,
	sluttishly: 1,
	sluttishness: 1,
	sluttishnesses: 1,
	slutty: 1,
	sodomy: 1,
	spic: 1,
	spics: 1,
	spiks: 1,
	superbitch: 1,
	syph: 1,
	syphs: 1,
	tits: 1,
	titty: 1,
	trannies: 1,
	turdlicker: 1,
	twat: 1,
	twats: 1,
	wanker: 1,
	wigger: 1,
	wiggers: 1,
	wogs: 1,
	ziggaboo: 1,
	ziggaboos: 1,
};
