import { isEmpty } from "@utils";

export default ({ app, route, redirect }) => {
	/**
	 * Add trailing slash where necessary.
	 */

	const parsedQs = Object.keys(route.query)
		.reduce((a, key) => {
			if (
				["order_by", "dictionary"].includes(key) &&
				isEmpty(route.query[key])
			) {
				return a;
			}

			if (isEmpty(route.query[key])) {
				a.push(key);
				return a;
			}

			a.push(`${key}=${route.query[key]}`);
			return a;
		}, [])
		.join("&");

	if (route?.path?.substr(-1) !== "/") {
		return redirect(301, `${route.path}/${parsedQs ? `?${parsedQs}` : ""}`);
	}
};
