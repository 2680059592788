import { mapActions } from "vuex";

export default {
	name: "AccountRegisterButton",
	data() {
		return {
			hoverOnUserIcon: false,
		};
	},
	methods: {
		...mapActions("account", [
			"OBSERVE_USER_LOGIN_CHANGES",
			"OPEN_LOGIN_DIALOG",
		]),
		buttonClick() {
			this.OBSERVE_USER_LOGIN_CHANGES();
			this.OPEN_LOGIN_DIALOG();
		},
	},
};
