/**
 * Maximum widths for each device category. Used for ads, as well as UI behaviour
 * differentiation between mobile, tablet, desktop, etc.
 */
export const SMALL_DEVICE_WIDTH_MAX = 767;
export const MEDIUM_PORTRAIT_WIDTH_MAX = 959;
export const MEDIUM_DEVICE_WIDTH_MAX = 1024;
export const LARGE_DEVICE_WIDTH_MAX = 1200;

/**
 * Header measurements, mostly used to calculate sticky behaviour.
 */
export const HEADER_HEIGHT = {
	TOUCH_DEVICE: 51,
	DESKTOP: 70,
};
