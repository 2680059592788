import { render, staticRenderFns } from "./AccountButtons.vue?vue&type=template&id=6744f462&scoped=true&"
import script from "./AccountButtons.js?vue&type=script&lang=js&"
export * from "./AccountButtons.js?vue&type=script&lang=js&"
import style0 from "./AccountButtons.scss?vue&type=style&index=0&id=6744f462&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6744f462",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountTeaser: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/account/AccountButtons/AccountTeaser/AccountTeaser.vue').default,LoginButton: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/account/AccountButtons/LoginButton/LoginButton.vue').default,LoginModal: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/account/AccountButtons/LoginModal/LoginModal.vue').default})
