/**
 * Checks if obj is an empty Object
 * @param {Object} obj
 * @return {Boolean} True if obj is empty. False if not
 */
export function isEmpty(obj) {
	return (
		typeof obj === "undefined" ||
		!obj ||
		(obj instanceof Array && obj.length === 0) ||
		(Object.keys(obj).length === 0 && obj.constructor === Object)
	);
}

/**
 * Takes a source array and returns a unique array of randomly
 * picked items from the source array. The length of the
 * returned array is determined by the count parameter.
 * @param {Array} sourceArray The source array to pick from.
 * @param {Number} count The number of items to pick. Must be lower than the array length
 *
 * @returns {Array} The resulting array
 */
export function getRandomItemsFromArray(sourceArray = [], count = 1) {
	const result = new Array(count);
	let len = sourceArray.length;
	const taken = new Array(len);

	if (count > len) {
		throw new RangeError(
			"getRandomItemsFromArray: more elements taken than available"
		);
	}

	while (count--) {
		const x = Math.floor(Math.random() * len);
		result[count] = sourceArray[x in taken ? taken[x] : x];
		taken[x] = --len in taken ? taken[len] : len;
	}

	return result;
}

/**
 * Creates an array with empty strings given a number of indexes.
 * Also merges previous array values if an additional argument is given.
 *
 * @param {Number} num Number of items in the array
 * @param {Array} previousArray Previous value that will be merged with the new result
 * @returns {Array} An array with the given length
 */
export const buildModelArray = (num, previousArray = []) => {
	const a = [];

	for (let i = 0; i < num; i++) {
		a.push(previousArray[i] || "");
	}

	return a;
};

/**
 * Sort object keys
 *
 * @param {Object} obj the words object what should be sorted by descending
 * @return {Array} array of sorted object keys by descending
 */
export const sortObjectKeys = (obj) => {
	return Object.keys(obj)
		.filter((k) => obj[k])
		.sort((a, b) => {
			const lettersRegex = /[^\d]/g;
			const getNum = (str) => Number(str.replace(lettersRegex, ""));
			return getNum(b) - getNum(a);
		});
};
