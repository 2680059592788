/**
 * Takes a compressed nested dictionary and expands it back into a flat object.
 *
 * @param {Object.<string, any>} sourceData The compressed dictionary
 * @param {string} prefix For nodes nested further in, this provides a prefix to help build the expanded dictionary
 *
 * @returns {Object.<string, string>} The uncompressed flat dictionary
 */
export const expandDictionary = (sourceData = {}, prefix = "") => {
	let output = {};
	Object.entries(sourceData).forEach(([nodeKey, nodeValue]) => {
		const prefixedNodeKey = prefix + nodeKey;
		if (typeof nodeValue === "string") {
			output[prefixedNodeKey] = nodeValue;
		} else if (typeof nodeValue === "object") {
			output = {
				...output,
				...expandDictionary(nodeValue, prefixedNodeKey),
			};
		}
	});

	return output;
};
