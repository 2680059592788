export const DICTIONARIES_IN_API = {
	ALL: "WL", // Look in all dictionaries
	BIG: "BIG", // World's Biggest Crossword
	BRAIN: "BRAIN", // Word Brain
	CHU: "CHU", // Word Chums
	CONNECT: "CONNECT", // Word Connect
	COOKIES: "COOKIES", // Word Cookies
	CROSS: "CROSS", // CodyCross
	CROSSY: "CROSSY", // Word Crossy
	CWF: "CWF", // Crossword with Friends
	DOM: "DOM", // Word Domination
	FEU: "FEU", // Wordfeud
	HQ: "HQ", // HQ
	JOURNEY: "JOURNEY", // Word Journey
	JUM: "JUM", // Just Jumble
	NYTC: "NYTC", // NY Times Crossword
	NYTS: "NYTS", // NY Times Spelling
	PICS: "PICS", // 4 Pics 1 Word
	PICTO: "PICTO", // Pictoword
	STORY: "STORY", // Words Story
	SWIPE: "SWIPE", // Word Swipe
	TRIP: "TRIP", // Word Trip
	TWIST: "TWIST", // Text Twist
	US: "US", // Scrabble US
	UK: "UK", // Scrabble UK
	WWF: "WWF", // Words With Friends
	YDR: "YDR", // YDR definition available
	ANAGRAM: "ANAGRAM", // YDR definition available
	WS: "SCAPE", // Wordscapes
	WARS: "WARS", // Word wars
	WORDLE: "WORDLE", // Wordle and Quordle dictionary
	WORDLE_ALL: "WORDLE_ALL", // Wordle dictionary (all playable words, not only for nyt wordle)
};

/**
 * @todo the Vue site has a better way of doing this,
 * migrate towards that one instead
 * Is this even used anymore?
 */
export const DICTIONARIES = {
	1: DICTIONARIES_IN_API.WWF,
	2: DICTIONARIES_IN_API.US,
	3: DICTIONARIES_IN_API.UK,
	4: DICTIONARIES_IN_API.ANAGRAM,
	5: DICTIONARIES_IN_API.PICS,
	6: DICTIONARIES_IN_API.JUM,
	7: DICTIONARIES_IN_API.CONNECT,
	8: DICTIONARIES_IN_API.COOKIES,
	9: DICTIONARIES_IN_API.CHU,
	10: DICTIONARIES_IN_API.TWIST,
	11: DICTIONARIES_IN_API.CROSS,
	12: DICTIONARIES_IN_API.FEU,
	13: DICTIONARIES_IN_API.BRAIN,
	14: DICTIONARIES_IN_API.STORY,
	15: DICTIONARIES_IN_API.SWIPE,
	16: DICTIONARIES_IN_API.ALL,
	17: DICTIONARIES_IN_API.WS,
	18: DICTIONARIES_IN_API.WARS,
};

/*
 * Is this even used anymore?
 */
export const DICTIONARIES_IN_MENU = [
	1, 2, 3, 17, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
];

export const GAME_ICON_POSITION_IN_SPRITE = {
	WWF: 2,
	US: 1,
	UK: 1,
	WS: 16,
	ANAGRAM: 3,
	PICS: 4,
	JUM: 5,
	CONNECT: 7,
	COOKIES: 6,
	CHU: 8,
	TWIST: 9,
	CROSS: 10,
	FEU: 11,
	BRAIN: 12,
	STORY: 13,
	SWIPE: 14,
	ALL: 15,
	WARS: 17,
};

export const DICTIONARY_LISTS = {
	SCRABBLE_ONLY: [DICTIONARIES_IN_API.US, DICTIONARIES_IN_API.UK],
	DEFAULT: [
		DICTIONARIES_IN_API.WWF,
		DICTIONARIES_IN_API.US,
		DICTIONARIES_IN_API.UK,
		DICTIONARIES_IN_API.WS,
		DICTIONARIES_IN_API.ANAGRAM,
		DICTIONARIES_IN_API.PICS,
		DICTIONARIES_IN_API.JUM,
		DICTIONARIES_IN_API.CONNECT,
		DICTIONARIES_IN_API.COOKIES,
		DICTIONARIES_IN_API.CHU,
		DICTIONARIES_IN_API.TWIST,
		DICTIONARIES_IN_API.CROSS,
		DICTIONARIES_IN_API.FEU,
		DICTIONARIES_IN_API.BRAIN,
		DICTIONARIES_IN_API.STORY,
		DICTIONARIES_IN_API.SWIPE,
		DICTIONARIES_IN_API.WORDLE,
		DICTIONARIES_IN_API.WORDLE_ALL,
		DICTIONARIES_IN_API.WARS,
		DICTIONARIES_IN_API.ALL,
	],
};

// Used for the top right corner button on mobile
export const GAME_ICONS_SPRITE_META_SMALLER_ICONS = {
	offsetY: 11,
	iconHeight: 24,
	position: GAME_ICON_POSITION_IN_SPRITE,
};

// Used for the dropdowns on desktop
export const GAME_ICONS_SPRITE_META_SMALL_ICONS = {
	offsetY: 12,
	iconHeight: 26,
	position: GAME_ICON_POSITION_IN_SPRITE,
};

// Used for the list shown on mobile devices
export const GAME_ICONS_SPRITE_META_BIG_ICONS = {
	offsetY: 26,
	iconHeight: 58,
	position: GAME_ICON_POSITION_IN_SPRITE,
};
