export default ({ store }, inject) => {
	window.pbjs = window.pbjs || { que: [] };

	window.tude = window.tude || {};
	window.tude.cmd = window.tude.cmd || [];

	const loadAditude = () => {
		const isDebug =
			store?.getters["features/isFeatureEnabled"]("DebugMode") &&
			store?.getters["features/isFeatureEnabled"]("DebugAds");

		// Begin Aditude code
		const id = "wf_aditude";
		if (document.getElementById(id)) {
			if (isDebug) {
				// eslint-disable-next-line no-console
				console.debug(
					"ADITUDE DEBUG >> Aditude script already loaded."
				);
			}
			return;
		}
		const s = document.createElement("script");
		s.async = true;
		s.src =
			"https://dn0qt3r0xannq.cloudfront.net/lovetoknow-1r2kdxaraW/wordfinder/prebid-load.js";
		s.id = id;
		const firstScript = document.getElementsByTagName("script")[0];
		firstScript.parentNode.insertBefore(s, firstScript);
		if (isDebug) {
			// eslint-disable-next-line no-console
			console.debug("ADITUDE DEBUG >> Aditude script loaded", s);
		}
		// End Aditude code
	};

	inject("aditude", { loadAditude });
};
