import { isEmpty } from "@utils";
import {
	getBucketConfigOverrides,
	getBucketId,
	getBucketURL,
	readBucketConfigFromLocalStorage,
	writeBucketConfigToLocalStorage,
	isFeatureEnabled as staticIsFeatureEnabled,
} from "@/common/feature-toggling";

export const state = () => ({
	configId: "",
	toggles: [],
	overrides: [],
	loaded: false,
});

export const getters = {
	isFeatureEnabled: (state) => (toggleKey) => {
		if (!state.loaded) {
			return staticIsFeatureEnabled(toggleKey);
		}

		// The highest priority is the URL override, so check that first.
		const urlOverrideValue = state.overrides.find((override) => {
			return (
				override.toggleKey === toggleKey && override.source === "url"
			);
		});
		if (!isEmpty(urlOverrideValue)) {
			return urlOverrideValue.value;
		}

		// Then check the localstorage override.
		const localStorageOverrideValue = state.overrides.find((override) => {
			return (
				override.toggleKey === toggleKey &&
				override.source === "localstorage"
			);
		});
		if (!isEmpty(localStorageOverrideValue)) {
			return localStorageOverrideValue.value;
		}

		// Next check if this key is among the toggles, and return the resulting boolean.
		return state.toggles.includes(toggleKey);
	},
	allToggles: (state) => {
		const all = {};
		state.toggles.forEach((toggleKey) => {
			all[toggleKey] = {
				toggleKey,
				value: 1,
				source: "config",
			};
		});

		state.overrides
			.filter((override) => override.source === "localstorage")
			.forEach((override) => {
				all[override.toggleKey] = {
					toggleKey: override.toggleKey,
					value: override.value,
					source: override.source,
				};
			});

		state.overrides
			.filter((override) => override.source === "url")
			.forEach((override) => {
				all[override.toggleKey] = {
					toggleKey: override.toggleKey,
					value: override.value,
					source: override.source,
				};
			});

		return Object.entries(all)
			.map(([toggleKey, toggle]) => toggle)
			.sort((a, b) => a.toggleKey - b.toggleKey);
	},
};

export const mutations = {
	SET_FEATURE_TOGGLE_BUCKET_CONFIG: (state, config) => {
		state.configId = config.configId;
		state.toggles = config.toggles;
	},
	SET_FEATURE_TOGGLE_BUCKET_OVERRIDES: (state, overrides) => {
		state.overrides = overrides;
	},
	SET_LOADED: (state, loadedValue) => {
		state.loaded = loadedValue;
	},
};

export const actions = {
	async init(context) {
		context.commit(
			"SET_FEATURE_TOGGLE_BUCKET_OVERRIDES",
			getBucketConfigOverrides()
		);

		const bucketId = getBucketId();

		// Check the bucket ID.
		if (typeof bucketId !== "number" || bucketId < 1 || bucketId > 100) {
			return console.error("TBL >> Invalid bucket ID", { bucketId });
		}

		const bucketURL = getBucketURL(bucketId);

		let configId;
		let toggles;

		try {
			const response = await this.$axios.$get(bucketURL);
			configId = response.configId;
			toggles = response.toggles;
		} catch (e) {
			return console.error("TBL >> Unable to get TBL bucket data");
		}

		if (typeof configId !== "string" || !configId.length) {
			return console.error(
				"TBL >> Config ID string received is invalid",
				{ configId }
			);
		}

		if (!/^\d{8}_\d+$/.test(configId)) {
			return console.error(
				"TBL >> Config ID string received is in an incorrect format",
				{ configId }
			);
		}

		if (typeof toggles !== "string") {
			return console.error("TBL >> Toggles string received is invalid", {
				toggles,
			});
		}

		if (toggles.length && !/^[a-zA-Z0-9_|]+$/.test(toggles)) {
			return console.error(
				"TBL >> Toggles string received is in an incorrect format",
				{ toggles }
			);
		}

		const toggleArray = toggles.length ? toggles.split("|") : [];

		let currentConfig = readBucketConfigFromLocalStorage();
		if (currentConfig === null || currentConfig.configId !== configId) {
			currentConfig = writeBucketConfigToLocalStorage(
				configId,
				toggleArray
			);
		}

		context.commit("SET_FEATURE_TOGGLE_BUCKET_CONFIG", currentConfig);
		context.commit("SET_LOADED", true);
	},
};
