import { ZEBRALIST } from "@consts";
import { isFeatureEnabled } from "@/common/feature-toggling";

/**
 * Takes a route path and checks against the hybrid list to see
 * if it should be served as a Vue route.
 * @param {string} path The route path
 * @param {boolean} debug Prints debug info if set to true.
 * @returns {boolean} If true, the route is not listed (i.e. it is a Vue route).
 */
export const isVueRoute = (path, debug = false) => {
	const printDebug = (str) => {
		if (debug) {
			// eslint-disable-next-line no-console
			console.debug(`ZEBRALIST >>> ${str}`);
		}
	};

	printDebug(`Checking hybrid route for ${path}...`);

	let staticMatch = false;
	let dynamicMatch = false;

	if ((staticMatch = isStaticRouteMatch(path))) {
		printDebug(
			`${path} matches ${staticMatch}, this is a static Vue route.`
		);
		return true;
	}

	if ((dynamicMatch = isDynamicRouteMatch(path))) {
		printDebug(
			`${path} matches ${dynamicMatch}, this is a dynamic Vue route.`
		);
		return true;
	}

	printDebug(`No match found for ${path}, therefore this is a Nuxt route.`);
	return false;
};

/**
 * Checks if a route matches one of the dynamic routes.
 * @param {string} path
 * @returns {bool}
 */
export const isDynamicRouteMatch = (path) => {
	return ZEBRALIST.DYNAMIC.find((dynamicPath) => {
		const regex = new RegExp(`^${dynamicPath}`, "i");
		return regex.test(path);
	});
};

/**
 * Checks if a route matches a static path.
 * @param {string} path
 * @returns {bool}
 */
export const isStaticRouteMatch = (path) => {
	return ZEBRALIST.STATIC.find((staticPath) => staticPath === path);
};

export const isHybridEnvironment = (origin) => {
	const hybridDomains = [
		"wordfinder.yourdictionary.com",
		"wordfinder-stg.yourdictionary.com",
	];

	return hybridDomains.some((domain) => {
		return new RegExp(domain, "i").test(origin);
	});
};

export const isHybridLocalEnvironment = (origin) => {
	const hybridDomains = ["wordfinder-local.yourdictionary.com"];

	return (
		isFeatureEnabled("LocalZebraList") &&
		hybridDomains.some((domain) => {
			return new RegExp(domain, "i").test(origin);
		})
	);
};
