import { isEmpty } from "@utils";

export default (context) => {
	const firebaseKeyStartRegex = /^firebase:authUser:/;
	let firebaseUserKey = null;

	for (let i = 0; i < localStorage.length; i++) {
		if (firebaseKeyStartRegex.test(localStorage.key(i))) {
			firebaseUserKey = localStorage.key(i);
		}
	}

	if (firebaseUserKey) {
		context.store.commit("account/UPDATE_PREVIOUSLY_LOGGED_IN", true);
		context.store.dispatch("account/OBSERVE_USER_LOGIN_CHANGES");
	} else {
		context.store.commit("account/UPDATE_PREVIOUSLY_LOGGED_IN", false);
	}
};
