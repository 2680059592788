import { isResultsPage } from "@utils";

/**
 * When navigating from a results page to a
 * non-results page, clear the saved results.
 */
export default ({ route, store, from }) => {
	if (from && isResultsPage(from.name) && !isResultsPage(route.name)) {
		store.commit("query/clearSavedSearch");
	}
};
