import Vue from "vue";

Vue.directive("lazyloadimage", {
	inserted: (el, binding, vnode) => {
		function loadImage() {
			const picEls = el.getElementsByTagName("picture");
			const pictureElement = picEls.length ? picEls[0] : null;

			if (pictureElement) {
				const imageElement = pictureElement.lastElementChild;

				imageElement.addEventListener("error", () =>
					console.error("Error while trying to lazy load image.")
				);
				imageElement.src = imageElement.dataset.url;

				/**
				 * In Safari, the image's "load" event fails to fire. Thus, we create
				 * a second image element, set it to the same src image, and have IT
				 * listen for a load event instead.
				 *
				 * If it's stupid, and it works, it's not stupid.
				 */
				const dupeImage = new Image();
				dupeImage.onload = () => {
					setTimeout(() => el.classList.add("loaded"), 100);
				};
				dupeImage.src = imageElement.src;
			}
		}

		function handleIntersect(entries, observer) {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) {
					return true;
				} else {
					loadImage();
					observer.unobserve(el);
				}
			});
		}

		function createObserver() {
			const options = {
				// circumstances under which the observer's callback is invoked
				root: null, // defaults to the browser viewport if not specified or if null
				threshold: "0", // the degree of intersection between the target element and its root (0 - 1)
				// threshold of 1.0 means that when 100% of the target is visible within
				// the element specified by the root option, the callback is invoked
			};

			// Whether you're using the viewport or some other element as the root,the API works the same way,
			// executing a callback function you provide whenever the visibility of the target element changes
			// so that it crosses desired amounts of intersection with the root

			const observer = new IntersectionObserver(handleIntersect, options);

			observer.observe(el); // target element to watch
		}

		if (!window.IntersectionObserver) {
			loadImage();
		} else {
			createObserver();
		}
	},
});
