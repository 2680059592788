import { render, staticRenderFns } from "./TheNewHeader.vue?vue&type=template&id=71ea4f16&scoped=true&"
import script from "./TheNewHeader.js?vue&type=script&lang=js&"
export * from "./TheNewHeader.js?vue&type=script&lang=js&"
import style0 from "./TheNewHeader.scss?vue&type=style&index=0&id=71ea4f16&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71ea4f16",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/Logo/Logo.vue').default,TheGetTheAppButton: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/template/TheGetTheAppButton/TheGetTheAppButton.vue').default,TheDesktopNavigation: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/template/TheDesktopNavigation/TheDesktopNavigation.vue').default,AccountButtons: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/account/AccountButtons/AccountButtons.vue').default,TheBurgerButton: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/template/TheBurgerButton/TheBurgerButton.vue').default,TheMobileNavigation: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/template/TheMobileNavigation/TheMobileNavigation.vue').default})
