import {
	isResultsPage,
	isScrabbleDictionaryPage,
	isLandingPage,
	pageHasDedicatedDictionary,
} from "@utils";
import { PAGES } from "@consts";

export const state = () => ({
	currentRouteName: null,
	currentRouteQuery: {},
	firstTimeAccess: true,
	mobileMenuIsOpen: false,
	desktopSubMenuIsOpen: false,
	breadcrumbsOverride: [],
});

export const getters = {
	isAnyScrabblePage: (state) => {
		return [
			PAGES.SCRABBLE_DICT,
			PAGES.SCRABBLE_CHECKER,
			PAGES.SCRABBLE_DICT_RESULTS,
			PAGES.SCRABBLE_CHEAT,
		].includes(state.currentRouteName);
	},
	isLandingPage(state, getters) {
		return (
			isLandingPage(state.currentRouteName) ||
			getters.isWordListDistributorPage
		);
	},
	isResultsPage(state) {
		return isResultsPage(state.currentRouteName);
	},
	isWordListResultsPage(state) {
		return [
			PAGES.WORDS_STARTING_RESULTS,
			PAGES.WORDS_HAVING_RESULTS,
			PAGES.WORDS_ENDING_RESULTS,
			PAGES.WORDS_BY_LENGTH_RESULTS,
		].includes(state.currentRouteName);
	},
	isWordListCombinationResultsPage(state) {
		return [
			PAGES.WORDS_COMBINATION_LETTERS_RESULTS,
			PAGES.WORDS_COMBINATION_LENGTH_AND_LETTERS_RESULTS,
		].includes(state.currentRouteName);
	},
	isWordListCombinationLengthResultsPage(state) {
		return (
			state.currentRouteName ===
			PAGES.WORDS_COMBINATION_LENGTH_AND_LETTERS_RESULTS
		);
	},
	isWordListSpecialResultsPage(state) {
		return [
			PAGES.WORDS_WITH_Q_NO_U,
			PAGES.WORDS_VOWELS,
			PAGES.WORDS_CONSONANTS,
			PAGES.WORDS_ENDING_ING,
		].includes(state.currentRouteName);
	},
	isAnyWordListResultsPage(state, getters) {
		return (
			getters.isWordListResultsPage ||
			getters.isWordListCombinationResultsPage ||
			getters.isWordListSpecialResultsPage
		);
	},
	isHomePage(state) {
		return state.currentRouteName === PAGES.HOME;
	},
	isWordsByLengthResultsPage(state) {
		return state.currentRouteName === PAGES.WORDS_BY_LENGTH_RESULTS;
	},
	isWordsWithResultsPage(state) {
		return state.currentRouteName === PAGES.WORDS_HAVING_RESULTS;
	},
	isWordsStartingResultsPage(state) {
		return state.currentRouteName === PAGES.WORDS_STARTING_RESULTS;
	},
	isWordsEndingResultsPage(state) {
		return state.currentRouteName === PAGES.WORDS_ENDING_RESULTS;
	},
	/**
	 * @todo remove this, we're not using it.
	 */
	isScrabbleDictionaryPage(state) {
		return isScrabbleDictionaryPage(state.currentRouteName);
	},
	isScrabbleDictionaryResultsPage(state, getters) {
		return state.currentRouteName === PAGES.SCRABBLE_DICT_RESULTS;
	},
	isAnagramResultsPage(state) {
		return state.currentRouteName === PAGES.ANAGRAM_RESULTS;
	},
	isPersistentSearch(state, getters) {
		return (
			!getters.isWordleResultsPage &&
			!getters.isQuordleResultsPage &&
			getters.isResultsPage
		);
	},
	isUnscramblerResultsPage(state, getters) {
		return state.currentRouteName === PAGES.UNSCRAMBLER_RESULTS;
	},
	isWordleArticlesIndexPage(state) {
		return state.currentRouteName === PAGES.WORDLE_ARTICLES_INDEX;
	},
	isWordleArticleDetailPage(state) {
		return state.currentRouteName === PAGES.WORDLE_ARTICLE_DETAIL;
	},
	isWordlistIndexPage(state) {
		return state.currentRouteName === PAGES.WORDS_LISTS_INDEX;
	},
	isWordListDistributorPage(state) {
		return [
			PAGES.WORDS_LISTS_INDEX,
			PAGES.WORDS_STARTING_INDEX,
			PAGES.WORDS_HAVING_INDEX,
			PAGES.WORDS_ENDING_INDEX,
			PAGES.WORDS_BY_LENGTH_INDEX,
		].includes(state.currentRouteName);
	},
	pageHasDedicatedDictionary(state) {
		return pageHasDedicatedDictionary(state.currentRouteName);
	},
	isAnagramLandingPage(state) {
		return state.currentRouteName === PAGES.ANAGRAM;
	},
	isWordleLandingPage(state) {
		return state.currentRouteName === PAGES.WORDLE;
	},
	isQuordleLandingPage(state) {
		return state.currentRouteName === PAGES.QUORDLE;
	},
	isWordleResultsPage(state) {
		return state.currentRouteName === PAGES.WORDLE_RESULTS;
	},
	isQuordleResultsPage(state) {
		return state.currentRouteName === PAGES.QUORDLE_RESULTS;
	},
	isWordleGamePage(state, getters) {
		return getters.isWordleLandingPage || getters.isWordleResultsPage;
	},
	isQuordleGamePage(state, getters) {
		return getters.isQuordleLandingPage || getters.isQuordleResultsPage;
	},
	isCommerceWidgetPage(state, getters, rootState) {
		return false;
	},
};

export const mutations = {
	toggleMobileMenu(state, isOpen) {
		state.mobileMenuIsOpen =
			typeof isOpen === "boolean" ? isOpen : !state.mobileMenuIsOpen;
	},
	toggleDesktopSubMenu(state, isOpen) {
		state.desktopSubMenuIsOpen =
			typeof isOpen === "boolean" ? isOpen : !state.desktopSubMenuIsOpen;
	},
	setCurrentRouteName(state, routeName) {
		state.currentRouteName = routeName;
	},
	setCurrentRouteQuery(state, query) {
		state.currentRouteQuery = query;
	},
	setBreadcrumbsOverride(state, override = []) {
		state.breadcrumbsOverride = override;
	},
	setFirstTimeAccess(state, isFirstTimeAccess) {
		state.firstTimeAccess = isFirstTimeAccess;
	},
};
