import {
	DICTIONARY_STORAGE_KEY,
	DICTIONARIES_IN_API,
	PAGES,
	SEARCH_GAMES,
	UNSCRAMBLER_MAX_LENGTH,
	UNSCRAMBLER_MAX_WILDCARDS,
} from "@consts";
import { getDictionaryApiInternalKeyFromValue } from "@utils";
import { isEmpty } from "./misc";
import { writeLocalStorage, readLocalStorage } from "./storage";
import { BANNED_WORDS } from "@/common/consts/banned-words";

/**
 * @todo this should be renamed to `getDefaultDictionaryByRouteName` to be clearer.
 */
export const getDefaultDictionariesByPage = (routeName = "") => {
	if (typeof routeName !== "string") {
		routeName = "";
	}

	switch (routeName) {
		case PAGES.UNSCRAMBLE:
		case PAGES.WWF:
		case PAGES.WORDS_CONSONANTS:
		case PAGES.WORDS_VOWELS:
		case PAGES.WORDS_ENDING_ING:
		case PAGES.WORDS_WITH_Q_NO_U:
		case PAGES.WORDS_BY_LENGTH_RESULTS:
		case PAGES.WORDS_ENDING_RESULTS:
		case PAGES.WORDS_HAVING_RESULTS:
		case PAGES.WORDS_STARTING_RESULTS:
		case PAGES.WORDS_COMBINATION_LETTERS_RESULTS:
		case PAGES.WORDS_COMBINATION_LENGTH_AND_LETTERS_RESULTS:
		case PAGES.WORDS_LISTS_INDEX:
		case PAGES.WORDS_HAVING_INDEX:
		case PAGES.WORDS_ENDING_INDEX:
		case PAGES.WORDS_STARTING_INDEX:
		case PAGES.WORDS_BY_LENGTH_INDEX:
			return DICTIONARIES_IN_API.WWF;
		case PAGES.HOME:
		case PAGES.WORD_DESCRAMBLER:
		case PAGES.SCRABBLE_DICT:
		case PAGES.SCRABBLE_CHECKER:
		case PAGES.SCRABBLE_CHEAT:
		case PAGES.SCRABBLE_DICT_RESULTS:
		case PAGES.WORD_MAKER:
			return DICTIONARIES_IN_API.US;
		case PAGES.WORDFEUD:
			return DICTIONARIES_IN_API.FEU;
		case PAGES.WORD_CONNECT:
			return DICTIONARIES_IN_API.CONNECT;
		case PAGES.WORDBRAIN_ANSWERS:
			return DICTIONARIES_IN_API.BRAIN;
		case PAGES.WORD_STORY:
			return DICTIONARIES_IN_API.STORY;
		case PAGES.WORD_CHUMS_CHEAT:
			return DICTIONARIES_IN_API.CHU;
		case PAGES.UNSCRAMBLER_RESULTS:
			return DICTIONARIES_IN_API.WWF;
		case PAGES.ANAGRAM:
		case PAGES.ANAGRAM_RESULTS:
			return DICTIONARIES_IN_API.ANAGRAM;
		case PAGES.FOURPICS:
			return DICTIONARIES_IN_API.PICS;
		case PAGES.JUMBLE_SOLVER:
			return DICTIONARIES_IN_API.JUM;
		case PAGES.WORDCOOKIES:
			return DICTIONARIES_IN_API.COOKIES;
		case PAGES.TEXT_TWIST:
			return DICTIONARIES_IN_API.TWIST;
		case PAGES.CODY_CROSS:
			return DICTIONARIES_IN_API.CROSS;
		case PAGES.WORD_SWIPE_CHEATS:
			return DICTIONARIES_IN_API.SWIPE;
		case PAGES.WORDLE:
		case PAGES.WORDLE_RESULTS:
			return DICTIONARIES_IN_API.WORDLE_ALL;
		case PAGES.QUORDLE:
		case PAGES.QUORDLE_RESULTS:
			return DICTIONARIES_IN_API.WORDLE;
		case PAGES.WORD_WARS:
			return DICTIONARIES_IN_API.WARS;
		default:
			return DICTIONARIES_IN_API.ALL;
	}
};

/**
 * Checks if the current route has a dictionary
 * associated with it.
 * @param {string} routeName the name of the route
 * @returns {boolean}
 */
export const pageHasSpecificDictionary = (routeName) => {
	return getDefaultDictionariesByPage(routeName) !== DICTIONARIES_IN_API.ALL;
};

/**
 * Checks if the current route can ONLY take one
 * specific dictionary. These are typically pages
 * where you would not see the dictionary dropdown.
 * @param {string} routeName the name of the route
 * @returns {boolean}
 */
export const pageHasDedicatedDictionary = (routeName) => {
	return [
		PAGES.WWF,
		PAGES.FOURPICS,
		PAGES.ANAGRAM,
		PAGES.JUMBLE_SOLVER,
		PAGES.WORD_CHUMS_CHEAT,
		PAGES.WORDBRAIN_ANSWERS,
		PAGES.WORDCOOKIES,
		PAGES.WORDFEUD,
		PAGES.TEXT_TWIST,
		PAGES.CODY_CROSS,
		PAGES.WORD_SWIPE_CHEATS,
		PAGES.WORD_STORY,
		PAGES.WORD_CONNECT,
		PAGES.WORD_SCRAMBLE,
		PAGES.WORDLE,
		PAGES.WORDLE_RESULTS,
		PAGES.QUORDLE,
		PAGES.QUORDLE_RESULTS,
		PAGES.WORD_WARS,
	].includes(routeName);
};

export const createDictionaryObjectFromValue = (value) => {
	const id = getDictionaryApiInternalKeyFromValue(value) || value;
	return {
		id,
		text: `DictionaryMenuSection_${id}_Text`,
		value,
		titleAttribute: `DictionaryMenuSection_${id}_TitleAttribute`,
		ariaLabel: `DictionaryMenuSection_${id}_AriaLabel`,
	};
};

/**
 * Builds a meta info object for dictionaries from an array of keys
 *
 * @param {Array<String>} dictionaryList An array of strings. Ideally you would want to pass a list of dictionaries specified in DICTIONARY_LISTS const.
 * @returns {Array<Object>} Array of objects representing meta info for dictionaries, used in templates and the list of dictionaries in the dictionary selectors. An empty array will be return if the list passed down is empty
 */
export const getDictionaries = (dictionaryList = []) =>
	dictionaryList.map(createDictionaryObjectFromValue);

export const getSelectableDictionaries = (dictionaryList = []) =>
	getDictionaries(dictionaryList).filter(
		(dictionary) =>
			dictionary.id !== DICTIONARIES_IN_API.WORDLE &&
			dictionary.id !== DICTIONARIES_IN_API.WORDLE_ALL
	);
/**
 * Retrieves a specific dictionary meta info object for a given value in a given list
 *
 * @param {Array<String>} dictionaryList An array of strings. Ideally you would want to pass a list of dictionaries specified in DICTIONARY_LISTS const.
 * @param {String} val The value that we want to find
 * @returns {Object | undefined} Meta info object for dictionaries if the value is found in the given list. Otherwise an undefined value is returned back.
 */
export const getDictionaryByValue = (dictionaryList, val) => {
	return getDictionaries(dictionaryList).find((dict) => dict.value === val);
};

export const isWordleDictionary = (dictionary) => {
	return dictionary.value === DICTIONARIES_IN_API.WORDLE;
};

export const writeDictionary = (dictionary) => {
	writeLocalStorage(DICTIONARY_STORAGE_KEY, dictionary);
};

export const readDictionary = (defaultValue = null) => {
	if (!navigator.cookieEnabled) {
		return defaultValue;
	}
	const dict = readLocalStorage(DICTIONARY_STORAGE_KEY);

	// If the dictionary stored in Local Storage is no longer available, return the default value.
	if (
		!dict.length ||
		!Object.values(DICTIONARIES_IN_API).includes(dict[0].dict)
	) {
		return defaultValue;
	}

	return dict[0].dict;
};

/**
 * Return given tiles in alphabetical order
 * @param {String} tiles - A string of tiles
 * @return {String} Alphabetical ordered tiles
 */
export const alphabetizeTiles = (tiles) => {
	return [...tiles].sort().join("");
};

/**
 * Check Tiles entered by user are present in our blacklist or not
 *
 * @param {string} tiles The tiles entered by user for search
 * @return {string} true if entered tiles contain a full word that is present in our bannedWords hashmap
 */
export const isTilesInBlacklist = (tiles) => {
	return tiles.split(/[^a-z]/).some((word) => BANNED_WORDS[word]);
};

/**
 * Sanitize tiles and normalize wildcards
 * @param {String} tiles - User input
 * @param {Boolean} isUrl
 * @param {Boolean} allowExtraWildcards
 * @return {String} sanitized tiles
 */
export const sanitizeTiles = (
	tiles,
	isUrl = false,
	allowExtraWildcards = false,
	sanitisationRegex = /[a-z_]/i
) => {
	const lowerCasedTiles = tiles.toLowerCase();
	let wildcards = 0;
	let sanitized = "";

	for (let tile of [...lowerCasedTiles]) {
		if (tile === "?") {
			tile = "_";
		}

		sanitized +=
			!sanitisationRegex.test(tile) ||
			(!isUrl && tile === "-") ||
			(!allowExtraWildcards &&
				(tile === "_" && ++wildcards) > UNSCRAMBLER_MAX_WILDCARDS)
				? ""
				: tile;
		if (sanitized.length === UNSCRAMBLER_MAX_LENGTH) {
			break;
		}
	}

	return sanitized;
};

export const getLettersQueryParam = (letters, prefixString) => {
	const string = letters?.split(`${prefixString}-`)[1] || "";
	return string.includes("-")
		? string.substring(0, string.indexOf("-"))
		: string;
};

/**
 *
 * @param {string?} tiles
 * @param {string} game
 * @param {object} queryParams
 * @param {string?} queryParams.starts
 * @param {string?} queryParams.contains
 * @param {string?} queryParams.ends
 * @param {string | number | null} queryParams.length
 * @param {string?} queryParams.correct
 * @param {string?} queryParams.includes
 * @param {string?} queryParams.excludes
 * @returns {string?}
 */
export const getResultsUrl = (
	tiles = "",
	game = SEARCH_GAMES.UNSCRAMBLE,
	queryParams = {}
) => {
	tiles = typeof tiles === "string" ? tiles.toLowerCase() : "";

	if (!Object.values(SEARCH_GAMES).includes(game.toUpperCase?.())) {
		console.warn(
			`"${game}" is an invalid game parameter. Defaulting to "${SEARCH_GAMES.UNSCRAMBLE}"`
		);
		game = SEARCH_GAMES.UNSCRAMBLE;
	}

	game = game.toUpperCase();

	switch (game) {
		case SEARCH_GAMES.SCRABBLE: {
			if (tiles.length) {
				return `/scrabble-dictionary/${tiles}/`;
			}
			break;
		}
		case SEARCH_GAMES.ANAGRAM: {
			if (tiles.length) {
				return `/anagram-solver/${tiles}/`;
			}
			break;
		}
		case SEARCH_GAMES.WORDLE:
		case SEARCH_GAMES.QUORDLE: {
			const path =
				game === "WORDLE" ? "/wordle/results/" : "/quordle/results/";

			const params = {};
			if (queryParams.correct?.length) {
				params.correct = queryParams.correct;
			}

			if (queryParams.includes?.length) {
				params.includes = queryParams.includes;
			}

			if (queryParams.excludes?.length) {
				params.excludes = queryParams.excludes;
			}

			if (Object.keys(params).length) {
				const queryString = Object.entries(params)
					.map(([key, value]) => `${key}=${value}`)
					.join("&");
				return `${path}?${queryString}`;
			}

			break;
		}
		case SEARCH_GAMES.UNSCRAMBLE: {
			if (!tiles.length) {
				const wordlistUrl = buildWordListPathFromFields(
					queryParams.length?.toString?.(),
					queryParams.starts,
					queryParams.contains,
					queryParams.ends
				);
				if (wordlistUrl) {
					return wordlistUrl;
				}
			} else {
				return `/unscramble/${tiles}/`;
			}
			break;
		}
	}

	return null;
};

/**
 * Takes the 4 advanced search parameters, and attempts to build a
 * @param {string?|number?} length The length field value
 * @param {string?} starts The starts field value
 * @param {string?} contains The contains/with/having field value
 * @param {string?} ends The ends field value
 * @returns
 */
export const buildWordListPathFromFields = function (
	length = "",
	starts = "",
	contains = "",
	ends = ""
) {
	// If a number is supplied, convert it to a string. Because we're nice.
	if (typeof length === "number") {
		length = String(length);
	}

	// Default everything to an empty string if not a valid string value.
	[length, starts, contains, ends] = [length, starts, contains, ends].map(
		(param) => (typeof param === "string" && !isEmpty(param) ? param : "")
	);

	const paramCheck = {
		starts,
		ends,
		with: contains,
	};

	// Using an array ensures the order of the keys.
	const paramOrder = ["starts", "with", "ends"];
	const nonEmptyParams = paramOrder.reduce((acc, key) => {
		const value = paramCheck[key];
		if (!isEmpty(value)) {
			acc.push(`${key}-${value}`);
		}

		return acc;
	}, []);

	// All combinations that have the length param are automatically letter-word URLs.
	if (!isEmpty(length)) {
		let segments = [length];

		if (nonEmptyParams.length) {
			segments = [...segments, ...nonEmptyParams];
		}

		return `/letter-words/${segments.join("-")}/`;
	}

	// All remaining combinations that have more than 1 param are automatically words-with-the-letter URLs.
	if (nonEmptyParams.length > 1) {
		return `/words-with-the-letter/${nonEmptyParams.join("-")}/`;
	}

	// From here there can only ever be one param non-empty.
	if (!isEmpty(starts)) {
		return `/words-that-start/${starts}/`;
	}

	if (!isEmpty(ends)) {
		return ends === "ing" ? "/ending-in-ing/" : `/ending-with/${ends}/`;
	}

	if (!isEmpty(contains)) {
		return `/words-with-the-letter/${contains}/`;
	}

	console.warn(
		"Invalid parameter scenario reached for buildWordListPathFromFields",
		arguments
	);
	return "";
};

export const sortQueryParams = (str) => {
	if (typeof str !== "string" || !str.length) {
		console.warn(
			"Attempting to sort query params of invalid variable or empty string",
			str
		);
		return str;
	}

	if (!str.includes("?")) {
		console.warn(
			"Attempting to sort query params of string that does not have query params",
			str
		);
		return str;
	}

	const [head, tail] = str.split("?");
	const params = new URLSearchParams(tail);
	params.sort();
	return [head, params.toString()].join("?");
};
