import { MENU_ITEMS } from "@consts";
export default {
	name: "TheDesktopNavigation",
	data() {
		return {
			menuData: [],
		};
	},
	computed: {
		submenuIsOpen() {
			return this.menuData.some((entry) => entry.submenuIsOpen === true);
		},
	},
	watch: {
		submenuIsOpen(value) {
			this.$store.commit("navigation/toggleDesktopSubMenu", value);
		},
	},
	created() {
		const reduceCallback = (acc, item) => {
			if (item.mobileOnly === true) {
				return acc;
			}

			if (Array.isArray(item.submenu)) {
				item.submenu = item.submenu.reduce(reduceCallback, []);
			}

			acc.push({ ...item });
			return acc;
		};

		this.menuData = MENU_ITEMS.reduce(reduceCallback, []);
	},
};
