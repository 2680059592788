/** Limitations for Unscrambler. They apply both to Finder's Component search
 * box and to Unscrambler API calls.
 */
export const UNSCRAMBLER_MIN_LENGTH = 2;
export const UNSCRAMBLER_MAX_LENGTH = 20;
export const UNSCRAMBLER_MAX_WILDCARDS = 3;
export const API_MAX_RESULTS = 2500;

export const ADVANCED_FIELD_TYPES = {
	STARTS: "startsWith",
	WITH: "contains",
	ENDS: "endsWith",
	LENGTH: "wordLength",
};

export const MOBILE_BOX_CONTROLS_MODES = {
	GAMES: "games",
	SEARCH: "search",
};

export const ADVANCED_FIELD_DEFAULT_VALUES = {
	[ADVANCED_FIELD_TYPES.STARTS]: "",
	[ADVANCED_FIELD_TYPES.WITH]: "",
	[ADVANCED_FIELD_TYPES.ENDS]: "",
	[ADVANCED_FIELD_TYPES.LENGTH]: null,
};

export const QUERY_API_ENDPOINTS = {
	UNSCRAMBLER: "unscrambler",
	WORDLIST: "wordlist",
};

export const SEARCH_GAMES = {
	UNSCRAMBLE: "UNSCRAMBLE",
	SCRABBLE: "SCRABBLE",
	ANAGRAM: "ANAGRAM",
	WORDLE: "WORDLE",
	QUORDLE: "QUORDLE",
};
