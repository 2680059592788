import ClickOutside from "vue-click-outside";
import { mapActions } from "vuex";

export default {
	name: "AccountTeaser",
	directives: {
		ClickOutside,
	},
	data() {
		return {
			user: null,
			imgProfile: null,
			isProfileTeaserOpened: false,
		};
	},
	computed: {
		displayName() {
			return this.user.data.displayName;
		},
	},
	created() {
		this.user = this.$store.getters["account/getUser"];
		this.imgProfile = this.$store.getters["account/getUserImage"];
	},
	methods: {
		...mapActions("account", ["LOGOUT"]),
		disableBackgroundContent() {
			const body = document.body;
			body.style.overflow = this.isProfileTeaserOpened ? "hidden" : null;
		},
		toggleProfileMenu() {
			this.isProfileTeaserOpened = !this.isProfileTeaserOpened;

			if (!this.isSmallDevice) {
				return;
			}

			this.disableBackgroundContent();
		},
		clickOutside(e) {
			if (e.target?.classList?.contains?.("profile-img")) {
				return;
			}
			this.toggleProfileMenu();
		},
		onLogout() {
			this.LOGOUT();
			this.toggleProfileMenu();

			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Login",
				eventAction: "Click on sign out menu section",
				eventLabel: "LoginMenuTextSignOut",
				eventID: 44,
			});
		},
	},
};
