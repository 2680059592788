import Vue from "vue";
import UiTemplateComponent from "@lovetoknow/ui-template-component";
import UiTableOfContents from "@lovetoknow/ui-table-of-contents";
import UiArticleBody from "@lovetoknow/ui-article-body";

const components = { UiTemplateComponent, UiTableOfContents, UiArticleBody };

Object.entries(components).forEach(([key, component]) => {
	Vue.component(key, component);
});
