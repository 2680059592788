export const subscribeUser = (axios, config, payload) =>
	axios.put(`${config.API_SERVICE_FND}/newsletter`, {
		...payload,
		website: "wordfinder",
	});

export const subscribeUserInYdr = (axios, config, payload, authToken) =>
	axios.put(`${config.API_SERVICE_YDR}/newsletter`, payload, {
		headers: { Authorization: `Bearer ${authToken}` },
	});

export const getUserSubscriptionInYdr = (axios, config, authToken) =>
	axios.get(`${config.API_SERVICE_YDR}/newsletter`, {
		headers: { Authorization: `Bearer ${authToken}` },
	});
