import {
	LOGO_TYPES,
	LARGE_DEVICE_WIDTH_MAX,
	SMALL_DEVICE_WIDTH_MAX,
} from "@consts";
import HybridLink from "@/components/HybridLink/HybridLink.vue";

export default {
	name: "Logo",
	components: {
		HybridLink,
	},
	props: {
		logoType: {
			type: String,
			default: LOGO_TYPES.WFW_EN,
		},
		label: {
			type: String,
			default: "",
			required: false,
		},
	},
	data() {
		return {
			logoData: {
				[LOGO_TYPES.WFW_EN]: {
					sources: [
						{
							url: "/static/images/ic_wfw_complete.webp",
							width: 197,
							height: 37,
							media: `(min-width: ${
								LARGE_DEVICE_WIDTH_MAX + 1
							}px)`,
							type: "image/webp",
						},
						{
							url: "/static/images/ic_wfw_complete.png",
							width: 197,
							height: 37,
							media: `(min-width: ${
								LARGE_DEVICE_WIDTH_MAX + 1
							}px)`,
						},
						{
							url: "/static/images/ic_wfw_complete.webp",
							width: 158,
							height: 31,
							media: `(min-width: ${
								SMALL_DEVICE_WIDTH_MAX + 1
							}px)`,
							type: "image/webp",
						},
						{
							url: "/static/images/ic_wfw_complete.png",
							width: 158,
							height: 31,
							media: `(min-width: ${
								SMALL_DEVICE_WIDTH_MAX + 1
							}px)`,
						},
						{
							url: "/static/images/ic_wfw_header.webp",
							width: 50,
							height: 32,
							type: "image/webp",
						},
					],
					base: {
						url: "/static/images/ic_wfw_header.png",
						width: 50,
						height: 32,
					},

					linkDictionaryKey: "/",
					attrDictionaryKey: "LogoTitleAttribute",
					altTextDictionaryKey: "LogoTitleAttribute",
					ariaLabelDictionaryKey: "LogoAriaLabel",
					gaEventLabel: "EN WF Logo",
					gaEventAction: "Click EN WF Logo navigation element",
					gaEventID: 2,
				},
				[LOGO_TYPES.WS]: {
					sources: [
						{
							url: "/static/images/ic_yd_wordscapes.webp",
							type: "image/webp",
							width: 32,
							height: 32,
						},
					],
					base: {
						url: "/static/images/ic_yd_wordscapes.png",
						width: 32,
						height: 32,
					},
					label: "Wordscapes",
					linkDictionaryKey: "HeaderSectionWordscapesUrl",
					attrDictionaryKey: "HeaderSectionWordscapesTitleAttribute",
					altTextDictionaryKey: "HeaderSectionWordscapesText",
					ariaLabelDictionaryKey: "HeaderSectionWordscapesAriaLabel",
					gaEventLabel: "Wordscapes Logo",
					gaEventAction: "Click on Wordscapes navigation element",
					gaEventID: 9,
				},
				[LOGO_TYPES.WFW_ES]: {
					sources: [
						{
							url: "/static/images/ic_wfw_header.webp",
							type: "image/webp",
							width: 32,
							height: 20.61,
						},
					],
					base: {
						url: "/static/images/ic_wfw_header.png",
						width: 32,
						height: 20.61,
					},
					label: "Spanish",
					labelDictionaryKey: "EsWfLogoText",
					linkDictionaryKey: "EsWfLogoUrl",
					attrDictionaryKey: "EsWfLogoTitleAttribute",
					altTextDictionaryKey: "EsWfLogoAltText",
					ariaLabelDictionaryKey: "EsWfLogoAriaLabel",
					gaEventLabel: "ES WF Logo",
					gaEventAction: "Click on ES WF Logo navigation element",
					gaEventID: 3,
				},
				[LOGO_TYPES.YDR]: {
					sources: [
						{
							url: "/static/images/ic_ydr_header.webp",
							type: "image/webp",
							width: 32,
							height: 32,
						},
					],
					base: {
						url: "/static/images/ic_ydr_header.png",
						width: 32,
						height: 32,
					},
					label: "Dictionary",
					labelDictionaryKey: "YdrLogoText",
					linkDictionaryKey: "HeaderYdrUrl",
					attrDictionaryKey: "HeaderYdrTitleAttribute",
					altTextDictionaryKey: "HeaderYdrLogoAltText",
					ariaLabelDictionaryKey: "HeaderYdrAriaLabel",
					gaEventLabel: "YDR Logo",
					gaEventAction: "Click on YDR Logo navigation element",
					gaEventID: 11,
				},
			},
		};
	},
	computed: {
		logoInfo() {
			const info = this.logoData[this.logoType];

			if (this.label?.length) {
				info.label = this.label;
			}

			return info;
		},
		isMainLogo() {
			return this.logoType === LOGO_TYPES.WFW_EN;
		},
		mainLogoOverrideStyle() {
			if (!this.isMainLogo) {
				return null;
			}

			if (this.isExtraLargeDevice) {
				return {
					width: "197px",
					height: "37px",
				};
			}

			if (this.isMediumDeviceOrWider) {
				return {
					width: "158px",
					height: "31px",
				};
			}

			return null;
		},
	},
	methods: {
		dispatchGaEvent() {
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Header",
				eventAction: this.logoInfo.gaEventAction,
				eventLabel: this.logoInfo.gaEventLabel,
				eventID: this.logoInfo.gaEventID,
			});
		},
	},
};
