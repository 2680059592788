const debounce = function (fn) {
	// Setup a timer
	let timeout;

	// Return a function to run debounced
	return function () {
		// Setup the arguments
		const context = this;
		const args = arguments;

		// If there's a timer, cancel it
		if (timeout) {
			window.cancelAnimationFrame(timeout);
		}

		// Setup the new requestAnimationFrame()
		timeout = window.requestAnimationFrame(function () {
			fn.apply(context, args);
		});
	};
};

export default (context, inject) => {
	// Add the window scroll event listener.
	const onScroll = () => {
		context.store.commit("window/setWindowScroll", window.pageYOffset);
	};

	const onResize = () => {
		const windowWidth =
			window?.innerWidth ||
			document?.documentElement.clientWidth ||
			document?.body.clientWidth;
		const windowHeight =
			window?.innerHeight ||
			document?.documentElement.clientHeight ||
			document?.body.clientHeight;

		context.store.commit("window/setWindowDimensions", {
			windowWidth,
			windowHeight,
		});
	};

	const scrollCallback = debounce(onScroll, 100);
	const resizeCallback = debounce(onResize, 100);

	window.addEventListener("scroll", scrollCallback);
	window.addEventListener("resize", resizeCallback);

	// Fire both callbacks once to record initial values.
	onScroll();
	onResize();
};
