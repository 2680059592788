
import { PAGES } from "@consts";

const singularTrademark = `is the property of its respective trademark owner. This trademark owner is not affiliated with, and do not endorse and/or sponsor, LoveToKnow®, its products or its websites, including <a href="https://www.yourdictionary.com/">yourdictionary.com</a>. Use of these trademarks on <a href="https://www.yourdictionary.com/">yourdictionary.com</a> is for informational purposes only.`;
const pluralTrademark = `are the property of their respective trademark owners. These trademark owners are not affiliated with, and do not endorse and/or sponsor, LoveToKnow®, its products or its websites, including <a href="https://www.yourdictionary.com">yourdictionary.com</a>. Use of this trademark on  <a href="https://www.yourdictionary.com/">yourdictionary.com</a> is for informational purposes only.`;

export default {
	name: "TheDisclaimer",

	computed: {
		shouldAppear() {
			// List of pages where the disclaimer should NOT appear
			// so this list acts as a blacklist
			return (
				[
					PAGES.NOT_FOUND,
					PAGES.ABOUT,
					PAGES.APP_PRIVACY_POLICY,
					PAGES.ARTICLE_INDEX,
					PAGES.ARTICLE_DETAIL,
				].includes(this.$route.name) === false
			);
		},

		disclaimerContent() {
			const defaultMessage = `SCRABBLE® and WORDS WITH FRIENDS® ${pluralTrademark}`;

			switch (this.$route.name) {
				case PAGES.HOME:
					return `SCRABBLE®, Words With Friends®, Word Chums® and Jumble® ${pluralTrademark}`;
				case PAGES.FOURPICS:
				case PAGES.SCRABBLE_DICT:
				case PAGES.WWF:
					return `4 PICS 1 WORD®, Words With Friends® and Scrabble® ${pluralTrademark}`;
				case PAGES.SCRABBLE_DICT_RESULTS:
					return `SCRABBLE® ${singularTrademark}`;
				case PAGES.UNSCRAMBLE:
					return `Jumble® is a registered trademark of Tribune Media Services, Inc. 4 PICS 1 WORD®, Words With Friends® and Scrabble® ${pluralTrademark}`;
				case PAGES.WORDCOOKIES:
					return `Word Cookies!®, 4 PICS 1 WORD®, Words With Friends® and Scrabble® ${pluralTrademark}`;
				case PAGES.WORDLE_ANSWERS:
				case PAGES.WORDLE_HINTS:
					return `Wordle is the property of their respective trademark owners. These trademark owners are not affiliated with, and do not endorse and/or sponsor, LoveToKnow®, its products or its websites, including <a href="//www.yourdictionary.com">yourdictionary.com</a>. Use of this trademark on <a href="//www.yourdictionary.com">yourdictionary.com</a> is for informational purposes only.`;
				default:
					return defaultMessage;
			}
		},
	},
};
