import { render, staticRenderFns } from "./error_layout_404.vue?vue&type=template&id=f927b8c2&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLayout: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/structural/BaseLayout.vue').default})
