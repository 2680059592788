var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"header z-super-low md:z-base-highest",class:{
		'header--no-border': !_vm.showBorder,
		'header--menu-bg': _vm.desktopSubMenuIsOpen,
		'no-bottom-space': _vm.isPersistentSearch && _vm.isSmallDevice,
	},style:(_vm.headerTopIndent)},[_c('div',{staticClass:"header-content",class:{
			'no-bottom-space': _vm.isPersistentSearch && _vm.isSmallDevice,
		}},[_c('Logo',{attrs:{"logo-type":_vm.LOGO_TYPES.WFW_EN}}),_vm._v(" "),_c('ClientOnly',[(_vm.isSmallDevice || _vm.isAppPage)?_c('TheGetTheAppButton'):_vm._e(),_vm._v(" "),_c('TheDesktopNavigation',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExtraLargeDevice && !_vm.isAppPage),expression:"isExtraLargeDevice && !isAppPage"}]}),_vm._v(" "),(!_vm.isAppPage)?_c('AccountButtons',{staticClass:"account-buttons-wrapper"}):_vm._e(),_vm._v(" "),(_vm.isExtraLargeDevice && !_vm.isAppPage)?_c('div',{staticClass:"external-logos-wrapper"},[_c('Logo',{staticClass:"external-logo",attrs:{"logo-type":_vm.LOGO_TYPES.WS}})],1):_vm._e()],1),_vm._v(" "),(!_vm.isAppPage)?[_c('TheBurgerButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isExtraLargeDevice),expression:"!isExtraLargeDevice"}]}),_vm._v(" "),_c('TheMobileNavigation',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isExtraLargeDevice),expression:"!isExtraLargeDevice"}]})]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }