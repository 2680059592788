/**
 * Takes a short date code (either YYMM or YYMMDD) and returns a formatted date
 * string for display. This is currently primarily used in the Wordle Answers and
 * Wordle Hints pages.
 * @param {string} dateCode The datecode. Should be either in YYMM or YYMMDD format.
 * @param {boolean} isSmallDevice Whether device is mobile or not. Defaults to true.
 * @returns {{year: string, month: string, day: string, formatted: string}} The formatted date string.
 */
export const getDateDataFromDateCode = (
	dateCode = "",
	isSmallDevice = true
) => {
	if (typeof dateCode !== "string") {
		console.warn(
			"Invalid date parameter provided, string required",
			dateCode
		);
		return {};
	}

	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const shortRegex = /^\d{4}$/;
	const longRegex = /^\d{6}$/;

	if (!shortRegex.test(dateCode) && !longRegex.test(dateCode)) {
		console.warn(
			"Date parameter has invalid format, YYMMDD or YYMM required",
			dateCode
		);
		return {};
	}

	const isDayDate = longRegex.test(dateCode);

	const output = {
		year: `20${dateCode.substr(0, 2)}`,
		month: months[parseInt(dateCode.substr(2, 2)) - 1],
	};

	if (isDayDate) {
		output.day = dateCode.substr(4, 2);

		if (isSmallDevice) {
			output.formatted = `${output.month.substr(0, 3)}. ${output.day}`;
		} else {
			output.formatted = `${output.month} ${output.day}, ${output.year}`;
		}
	} else {
		output.formatted = `${output.month} ${output.year}`;
	}

	return output;
};
