
import { LOGO_TYPES } from "@consts";

export default {
	data() {
		return {
			LOGO_TYPES,
		};
	},
	head() {
		return {
			title: this.$t(this.$route.meta.title),
			meta: [
				{
					hid: "description",
					name: "description",
					content: this.$t(this.$route.meta.description),
				},
			],
		};
	},
};
