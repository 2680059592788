import Vue from "vue";
import { readCCPACookie, writeCCPACookie, isLandingPage } from "@utils";
import {
	logOut,
	observeUserStateChanges,
	setPersistenceLevel,
	fetchClaims,
	fetchUserPreferences,
	updateNewsletterSubscription,
	getNewslettersSubscription,
	putUserPreferences,
} from "@/common/services/firebaseService";
import { fetchUserLocation } from "@/common/services/locationService";
import { NEWSLETTERS } from "@/common/consts";

export const state = () => ({
	isLoggedIn: false,
	user: {
		data: null,
		usprivacy: "1---",
		preferences: {},
		newslettersSubscription: null,
	},
	logInPopupBlocked: false,
	logInErrorMessage: "",
	isLoggingIn: false,
	isLogInDialogOpen: false,
	isSubscriptionDialogOpen: false,
	location: null,
	previouslyLoggedIn: false,
});

export const mutations = {
	UPDATE_IS_LOGGED_IN(state, newValue) {
		state.isLoggedIn = newValue;
	},
	UPDATE_USER_PREFERENCES: (state, value) => {
		Vue.set(state.user, "preferences", value);
	},
	UPDATE_USER_NEWSLETTER_SUBSCRIPTION: (state, value) => {
		state.user.newslettersSubscription = value;
	},
	UPDATE_LOG_IN_ERROR: (state, value) => {
		state.logInErrorMessage = value;
	},
	UPDATE_LOG_IN_POPUP_BLOCKED: (state, value) => {
		state.logInPopupBlocked = value;
	},
	UPDATE_IS_LOGGING_IN: (state, value) => {
		state.isLoggingIn = value;
	},
	UPDATE_USER_DATA: (state, value) => {
		state.user.data = value;
	},
	UPDATE_OPENED_MODAL_TYPE: (state, value) => {
		state.isLogInDialogOpen = value;
	},
	UPDATE_LOCATION: (state, value) => {
		state.location = value;
	},
	UPDATE_PREVIOUSLY_LOGGED_IN: (state, value) => {
		state.previouslyLoggedIn = value;
	},
	UPDATE_USPRIVACY: (state, value) => {
		if (!/^[1][nNyY-][nNyY-][nNyY-]$/.test(value)) {
			return;
		}
		state.user.usprivacy = value;

		if (value !== readCCPACookie()) {
			writeCCPACookie(value);
		}
	},
	SET_SUBSCRIPTION_DIALOG_STATE: (state, value) => {
		state.isSubscriptionDialogOpen = value;
	},
};

export const actions = {
	async LOGOUT({ commit }) {
		try {
			await logOut();
			commit("UPDATE_IS_LOGGED_IN", false);
			commit("UPDATE_USER_PREFERENCES", {});
			commit("UPDATE_USER_NEWSLETTER_SUBSCRIPTION", null);
		} catch (err) {
			console.error(err);
		}
	},
	async OBSERVE_USER_LOGIN_CHANGES(
		{ commit, dispatch },
		loggedInUserCallback = () => {}
	) {
		commit("UPDATE_LOG_IN_ERROR", null);
		commit("UPDATE_LOG_IN_POPUP_BLOCKED", false);
		await observeUserStateChanges(async (userOrNone) => {
			await dispatch("FETCH_USER", userOrNone);
			if (userOrNone) {
				loggedInUserCallback();
			}
		});
	},
	async FETCH_USER({ commit, dispatch }, user) {
		dispatch("GET_LOCATION");
		commit("UPDATE_IS_LOGGED_IN", user !== null);
		if (user) {
			commit("UPDATE_USER_DATA", {
				displayName: user.displayName,
				email: user.email,
				picture: user.photoURL,
			});
			await dispatch("FETCH_USER_CLAIMS");
			await dispatch("FETCH_USER_PREFERENCES");
			await dispatch("FETCH_NEWSLETTER_SUBSCRIPTION");
		} else {
			commit("UPDATE_USER_DATA", null);
		}
	},
	OPEN_FIREBASE_POPUP({ dispatch, commit }) {
		commit("UPDATE_IS_LOGGING_IN", true);
		commit("UPDATE_LOG_IN_ERROR", null);
		return setPersistenceLevel()
			.then(() => {
				commit("UPDATE_IS_LOGGING_IN", false);
				dispatch("CLOSE_LOGIN_DIALOG");
			})
			.catch((error) => {
				commit("UPDATE_IS_LOGGING_IN", false);
				if (error.code !== "auth/popup-closed-by-user") {
					commit("UPDATE_LOG_IN_ERROR", error.message);
				}

				if (error.code === "auth/popup-blocked") {
					commit("UPDATE_LOG_IN_POPUP_BLOCKED", true);
				}
			});
	},
	async GET_LOCATION({ commit, state }) {
		if (state.location) {
			return true;
		}

		const userCountryCode = await fetchUserLocation(this.$axios);
		commit("UPDATE_LOCATION", userCountryCode);
	},
	async FETCH_USER_CLAIMS({ commit }) {
		const hasUsPrivacy = await fetchClaims();
		commit("UPDATE_USPRIVACY", hasUsPrivacy || "1YNY");
	},
	OPEN_LOGIN_DIALOG({ commit }) {
		commit("UPDATE_OPENED_MODAL_TYPE", true);
	},
	CLOSE_LOGIN_DIALOG({ commit }) {
		commit("UPDATE_OPENED_MODAL_TYPE", false);
	},
	FETCH_NEWSLETTER_SUBSCRIPTION({ commit, dispatch }, params) {
		getNewslettersSubscription(this.$axios, this.$config, params)
			.then((response) => {
				const { data } = response.data;
				if (data.status) {
					commit("UPDATE_USER_NEWSLETTER_SUBSCRIPTION", data.status);
				}
				if (
					data.status !== NEWSLETTERS.OFF &&
					data.status !== NEWSLETTERS.ON
				) {
					commit("SET_SUBSCRIPTION_DIALOG_STATE", true);
				}
			})
			.catch((err) => {
				console.error(
					"Could not retrieve user's newsletter subscription.",
					err
				);
			});
	},
	NEWSLETTER_SUBSCRIPTION_ACTIVATION({ commit }, params) {
		updateNewsletterSubscription(this.$axios, this.$config, params)
			.then((response) => {
				const { data } = response.data;
				commit("SET_USER_NEWSLETTER_SUBSCRIPTION", data.status);
			})
			.catch((err) => {
				console.error(
					"Could not update user's newsletter subscription.",
					err
				);
			});
	},
	FETCH_USER_PREFERENCES({ commit, dispatch, rootState }) {
		fetchUserPreferences(this.$axios, this.$config)
			.then((response) => {
				const { data } = response.data;
				const preferences = data.results_page;
				// const groupByPreference = {
				// 	word_length: "wordlength",
				// 	none: "allWords",
				// };
				if (preferences) {
					/**
					 * @todo switch to using the `isLandingPage` getter
					 */
					if (!isLandingPage(rootState.navigation.current)) {
						// TODO: We will set the dictionary here
						// commit("setDictionary", preferences.dictionary, {
						// 	root: true,
						// });
					}
					commit("UPDATE_USER_PREFERENCES", preferences);
				} else {
					dispatch("SEND_USER_PREFERENCES");
				}
			})
			.catch((err) => {
				console.error("Could not retrieve user's preferences. ", err);
			});
	},
	SEND_USER_PREFERENCES({ commit, rootState }) {
		// const groupByPreference = {
		// 	wordlength: "word_length",
		// 	allWords: "none",
		// };

		// Check that current user preferences are different than latest query config and if so update them

		// if (
		// rootState.login.user.preferences.bonus_points !==
		// 	rootState.query.bonuspoints ||
		// rootState.login.user.preferences.dictionary !==
		// 	rootState.query.dictionary ||
		// rootState.login.user.preferences.group_by !==
		// 	groupByPreference[rootState.query.sortResults]
		// ) {
		const newPreferences = {
			// results_page: {
			// 	dictionary: rootState.query.dictionary,
			// 	group_by: groupByPreference[rootState.query.sortResults],
			// 	bonus_points: rootState.query.bonuspoints,
			// },
		};
		// TODO: We will update the new preferences in the BE here
		putUserPreferences(this.$axios, this.$config, newPreferences).then(
			() => {
				// TODO: We will update the new preferences in the store here
				commit("UPDATE_USER_PREFERENCES", {
					// dictionary: rootState.query.dictionary,
					// group_by: groupByPreference[rootState.query.sortResults],
					// bonus_points: rootState.query.bonuspoints,
				});
			}
		);
		// }
	},
};

export const getters = {
	isLoggedIn: (state) => state.isLoggedIn,
	getUser: (state) => state.user,
	getUserImage: (state) => state.user.data.picture,
	logInErrorMessage: (state) => state.logInErrorMessage,
	isLoading: (state) => state.isLoading,
	isLoggingIn: (state) => state.isLoggingIn,
};
