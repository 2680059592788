export const AboutFeaturedOn = () => import('../../components/AboutFeaturedOn/AboutFeaturedOn.vue' /* webpackChunkName: "components/about-featured-on" */).then(c => wrapFunctional(c.default || c))
export const Advertisement = () => import('../../components/Advertisement/Advertisement.vue' /* webpackChunkName: "components/advertisement" */).then(c => wrapFunctional(c.default || c))
export const ExcoAdvertisement = () => import('../../components/Advertisement/ExcoAdvertisement.vue' /* webpackChunkName: "components/exco-advertisement" */).then(c => wrapFunctional(c.default || c))
export const CommerceWidget = () => import('../../components/CommerceWidget/index.vue' /* webpackChunkName: "components/commerce-widget" */).then(c => wrapFunctional(c.default || c))
export const FinderController = () => import('../../components/FinderController/FinderController.vue' /* webpackChunkName: "components/finder-controller" */).then(c => wrapFunctional(c.default || c))
export const CrosswordSolverForm = () => import('../../components/Forms/CrosswordSolverForm.vue' /* webpackChunkName: "components/crossword-solver-form" */).then(c => wrapFunctional(c.default || c))
export const GameSelect = () => import('../../components/GameSelect/GameSelect.vue' /* webpackChunkName: "components/game-select" */).then(c => wrapFunctional(c.default || c))
export const GameSelectTrigger = () => import('../../components/GameSelect/GameSelectTrigger.vue' /* webpackChunkName: "components/game-select-trigger" */).then(c => wrapFunctional(c.default || c))
export const GameSelector = () => import('../../components/GameSelector/GameSelector.vue' /* webpackChunkName: "components/game-selector" */).then(c => wrapFunctional(c.default || c))
export const GameSelectorTrigger = () => import('../../components/GameSelector/GameSelectorTrigger.vue' /* webpackChunkName: "components/game-selector-trigger" */).then(c => wrapFunctional(c.default || c))
export const HomeSnippet = () => import('../../components/HomeSnippet/HomeSnippet.vue' /* webpackChunkName: "components/home-snippet" */).then(c => wrapFunctional(c.default || c))
export const HybridLink = () => import('../../components/HybridLink/HybridLink.vue' /* webpackChunkName: "components/hybrid-link" */).then(c => wrapFunctional(c.default || c))
export const LazyImage = () => import('../../components/LazyImage/LazyImage.vue' /* webpackChunkName: "components/lazy-image" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const NYTCard = () => import('../../components/NYTCard/NYTCard.vue' /* webpackChunkName: "components/n-y-t-card" */).then(c => wrapFunctional(c.default || c))
export const NYTLatest = () => import('../../components/NYTLatest/NYTLatest.vue' /* webpackChunkName: "components/n-y-t-latest" */).then(c => wrapFunctional(c.default || c))
export const NYTLatestList = () => import('../../components/NYTLatestList/NYTLatestList.vue' /* webpackChunkName: "components/n-y-t-latest-list" */).then(c => wrapFunctional(c.default || c))
export const NYTStrandsAccordionSection = () => import('../../components/NYTStrandsAccordionSection/NYTStrandsAccordionSection.vue' /* webpackChunkName: "components/n-y-t-strands-accordion-section" */).then(c => wrapFunctional(c.default || c))
export const NYTStrandsCard = () => import('../../components/NYTStrandsCard/NYTStrandsCard.vue' /* webpackChunkName: "components/n-y-t-strands-card" */).then(c => wrapFunctional(c.default || c))
export const EndMessage = () => import('../../components/NewsletterBanner/EndMessage.vue' /* webpackChunkName: "components/end-message" */).then(c => wrapFunctional(c.default || c))
export const Headings = () => import('../../components/NewsletterBanner/Headings.vue' /* webpackChunkName: "components/headings" */).then(c => wrapFunctional(c.default || c))
export const MailIcon = () => import('../../components/NewsletterBanner/MailIcon.vue' /* webpackChunkName: "components/mail-icon" */).then(c => wrapFunctional(c.default || c))
export const NewsletterBanner = () => import('../../components/NewsletterBanner/NewsletterBanner.vue' /* webpackChunkName: "components/newsletter-banner" */).then(c => wrapFunctional(c.default || c))
export const SubscribeState = () => import('../../components/NewsletterBanner/SubscribeState.vue' /* webpackChunkName: "components/subscribe-state" */).then(c => wrapFunctional(c.default || c))
export const SubscribeWithEmailCTA = () => import('../../components/NewsletterBanner/SubscribeWithEmailCTA.vue' /* webpackChunkName: "components/subscribe-with-email-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const SubscribeWithGoogleCTA = () => import('../../components/NewsletterBanner/SubscribeWithGoogleCTA.vue' /* webpackChunkName: "components/subscribe-with-google-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const SuccessState = () => import('../../components/NewsletterBanner/SuccessState.vue' /* webpackChunkName: "components/success-state" */).then(c => wrapFunctional(c.default || c))
export const WfPetImg = () => import('../../components/NewsletterBanner/WfPetImg.vue' /* webpackChunkName: "components/wf-pet-img" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const RelatedLinks = () => import('../../components/RelatedLinks/RelatedLinks.vue' /* webpackChunkName: "components/related-links" */).then(c => wrapFunctional(c.default || c))
export const SmsInput = () => import('../../components/SmsInput/SmsInput.vue' /* webpackChunkName: "components/sms-input" */).then(c => wrapFunctional(c.default || c))
export const WordCard = () => import('../../components/WordCard/WordCard.vue' /* webpackChunkName: "components/word-card" */).then(c => wrapFunctional(c.default || c))
export const CircleLoader = () => import('../../components/common/CircleLoader.vue' /* webpackChunkName: "components/circle-loader" */).then(c => wrapFunctional(c.default || c))
export const CustomSelect = () => import('../../components/common/CustomSelect.vue' /* webpackChunkName: "components/custom-select" */).then(c => wrapFunctional(c.default || c))
export const InputField = () => import('../../components/common/InputField.vue' /* webpackChunkName: "components/input-field" */).then(c => wrapFunctional(c.default || c))
export const InputStepper = () => import('../../components/common/InputStepper.vue' /* webpackChunkName: "components/input-stepper" */).then(c => wrapFunctional(c.default || c))
export const MultiInputField = () => import('../../components/common/MultiInputField.vue' /* webpackChunkName: "components/multi-input-field" */).then(c => wrapFunctional(c.default || c))
export const RadioButton = () => import('../../components/common/RadioButton.vue' /* webpackChunkName: "components/radio-button" */).then(c => wrapFunctional(c.default || c))
export const SearchField = () => import('../../components/common/SearchField.vue' /* webpackChunkName: "components/search-field" */).then(c => wrapFunctional(c.default || c))
export const WfButton = () => import('../../components/common/WfButton.vue' /* webpackChunkName: "components/wf-button" */).then(c => wrapFunctional(c.default || c))
export const WfChip = () => import('../../components/common/WfChip.vue' /* webpackChunkName: "components/wf-chip" */).then(c => wrapFunctional(c.default || c))
export const AnswersTable = () => import('../../components/games/AnswersTable.vue' /* webpackChunkName: "components/answers-table" */).then(c => wrapFunctional(c.default || c))
export const BaseLayout = () => import('../../components/structural/BaseLayout.vue' /* webpackChunkName: "components/base-layout" */).then(c => wrapFunctional(c.default || c))
export const GenericErrorLayout = () => import('../../components/structural/GenericErrorLayout.vue' /* webpackChunkName: "components/generic-error-layout" */).then(c => wrapFunctional(c.default || c))
export const TheBackToTopButton = () => import('../../components/template/TheBackToTopButton.vue' /* webpackChunkName: "components/the-back-to-top-button" */).then(c => wrapFunctional(c.default || c))
export const TheBreadcrumb = () => import('../../components/template/TheBreadcrumb.vue' /* webpackChunkName: "components/the-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const TheDisclaimer = () => import('../../components/template/TheDisclaimer.vue' /* webpackChunkName: "components/the-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const TheSidebar = () => import('../../components/template/TheSidebar.vue' /* webpackChunkName: "components/the-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AnswerSection = () => import('../../components/wordle/AnswerSection.vue' /* webpackChunkName: "components/answer-section" */).then(c => wrapFunctional(c.default || c))
export const HintsSection = () => import('../../components/wordle/HintsSection.vue' /* webpackChunkName: "components/hints-section" */).then(c => wrapFunctional(c.default || c))
export const FinderMinimal = () => import('../../components/FinderController/FormGroup/FinderMinimal.vue' /* webpackChunkName: "components/finder-minimal" */).then(c => wrapFunctional(c.default || c))
export const FinderRegular = () => import('../../components/FinderController/FormGroup/FinderRegular.vue' /* webpackChunkName: "components/finder-regular" */).then(c => wrapFunctional(c.default || c))
export const FinderWordle = () => import('../../components/FinderController/FormGroup/FinderWordle.vue' /* webpackChunkName: "components/finder-wordle" */).then(c => wrapFunctional(c.default || c))
export const AccountButtons = () => import('../../components/account/AccountButtons/AccountButtons.vue' /* webpackChunkName: "components/account-buttons" */).then(c => wrapFunctional(c.default || c))
export const NewsletterModal = () => import('../../components/account/NewsletterModal/NewsletterModal.vue' /* webpackChunkName: "components/newsletter-modal" */).then(c => wrapFunctional(c.default || c))
export const ArticleTeaser = () => import('../../components/articles/ArticleTeaser/ArticleTeaser.vue' /* webpackChunkName: "components/article-teaser" */).then(c => wrapFunctional(c.default || c))
export const LatestArticles = () => import('../../components/articles/LatestArticles/LatestArticles.vue' /* webpackChunkName: "components/latest-articles" */).then(c => wrapFunctional(c.default || c))
export const ReportWordDialog = () => import('../../components/common/ReportWordDialog/ReportWordDialog.vue' /* webpackChunkName: "components/report-word-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdvancedSearchControl = () => import('../../components/finder/AdvancedSearchControl/AdvancedSearchControl.vue' /* webpackChunkName: "components/advanced-search-control" */).then(c => wrapFunctional(c.default || c))
export const Finder = () => import('../../components/finder/Finder/Finder.vue' /* webpackChunkName: "components/finder" */).then(c => wrapFunctional(c.default || c))
export const WordleCorrectTilesInput = () => import('../../components/finder/WordleFinder/WordleCorrectTilesInput.vue' /* webpackChunkName: "components/wordle-correct-tiles-input" */).then(c => wrapFunctional(c.default || c))
export const WordleInput = () => import('../../components/finder/WordleFinder/WordleInput.vue' /* webpackChunkName: "components/wordle-input" */).then(c => wrapFunctional(c.default || c))
export const WordleInputLabel = () => import('../../components/finder/WordleFinder/WordleInputLabel.vue' /* webpackChunkName: "components/wordle-input-label" */).then(c => wrapFunctional(c.default || c))
export const MobileBoxControls = () => import('../../components/query/mobile-box-controls/MobileBoxControls.vue' /* webpackChunkName: "components/mobile-box-controls" */).then(c => wrapFunctional(c.default || c))
export const AlternativeResultsHint = () => import('../../components/results/AlternativeResultsHint/AlternativeResultsHint.vue' /* webpackChunkName: "components/alternative-results-hint" */).then(c => wrapFunctional(c.default || c))
export const Definition = () => import('../../components/results/Definition/Definition.vue' /* webpackChunkName: "components/definition" */).then(c => wrapFunctional(c.default || c))
export const DefinitionContent = () => import('../../components/results/Definition/DefinitionContent.vue' /* webpackChunkName: "components/definition-content" */).then(c => wrapFunctional(c.default || c))
export const PersistentSearch = () => import('../../components/results/PersistentSearch/PersistentSearch.vue' /* webpackChunkName: "components/persistent-search" */).then(c => wrapFunctional(c.default || c))
export const PlayableCard = () => import('../../components/results/PlayableCard/PlayableCard.vue' /* webpackChunkName: "components/playable-card" */).then(c => wrapFunctional(c.default || c))
export const ResultTable = () => import('../../components/results/ResultTable/ResultTable.vue' /* webpackChunkName: "components/result-table" */).then(c => wrapFunctional(c.default || c))
export const ResultsWrapper = () => import('../../components/results/ResultsWrapper/ResultsWrapper.vue' /* webpackChunkName: "components/results-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TableController = () => import('../../components/results/TableController/TableController.vue' /* webpackChunkName: "components/table-controller" */).then(c => wrapFunctional(c.default || c))
export const WordActions = () => import('../../components/results/WordActions/WordActions.vue' /* webpackChunkName: "components/word-actions" */).then(c => wrapFunctional(c.default || c))
export const LetterLengthBoxLinks = () => import('../../components/results/WordListInterlinkDirector/LetterLengthBoxLinks.vue' /* webpackChunkName: "components/letter-length-box-links" */).then(c => wrapFunctional(c.default || c))
export const WordListInterlinkDirector = () => import('../../components/results/WordListInterlinkDirector/WordListInterlinkDirector.vue' /* webpackChunkName: "components/word-list-interlink-director" */).then(c => wrapFunctional(c.default || c))
export const WordleCTA = () => import('../../components/results/WordleCTA/WordleCTA.vue' /* webpackChunkName: "components/wordle-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const TheArticlesPromo = () => import('../../components/template/TheArticlesPromo/TheArticlesPromo.vue' /* webpackChunkName: "components/the-articles-promo" */).then(c => wrapFunctional(c.default || c))
export const TheBurgerButton = () => import('../../components/template/TheBurgerButton/TheBurgerButton.vue' /* webpackChunkName: "components/the-burger-button" */).then(c => wrapFunctional(c.default || c))
export const TheDesktopNavigation = () => import('../../components/template/TheDesktopNavigation/TheDesktopNavigation.vue' /* webpackChunkName: "components/the-desktop-navigation" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/template/TheFooter/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheGetTheAppButton = () => import('../../components/template/TheGetTheAppButton/TheGetTheAppButton.vue' /* webpackChunkName: "components/the-get-the-app-button" */).then(c => wrapFunctional(c.default || c))
export const TheMobileNavigation = () => import('../../components/template/TheMobileNavigation/TheMobileNavigation.vue' /* webpackChunkName: "components/the-mobile-navigation" */).then(c => wrapFunctional(c.default || c))
export const TheNewHeader = () => import('../../components/template/TheNewHeader/TheNewHeader.vue' /* webpackChunkName: "components/the-new-header" */).then(c => wrapFunctional(c.default || c))
export const AccountTeaser = () => import('../../components/account/AccountButtons/AccountTeaser/AccountTeaser.vue' /* webpackChunkName: "components/account-teaser" */).then(c => wrapFunctional(c.default || c))
export const LoginButton = () => import('../../components/account/AccountButtons/LoginButton/LoginButton.vue' /* webpackChunkName: "components/login-button" */).then(c => wrapFunctional(c.default || c))
export const LoginModal = () => import('../../components/account/AccountButtons/LoginModal/LoginModal.vue' /* webpackChunkName: "components/login-modal" */).then(c => wrapFunctional(c.default || c))
export const SeeMoreButton = () => import('../../components/results/ResultTable/components/SeeMoreButton.vue' /* webpackChunkName: "components/see-more-button" */).then(c => wrapFunctional(c.default || c))
export const SortBySelector = () => import('../../components/results/TableController/components/SortBySelector.vue' /* webpackChunkName: "components/sort-by-selector" */).then(c => wrapFunctional(c.default || c))
export const DesktopNavigationEntry = () => import('../../components/template/TheDesktopNavigation/DesktopNavigationEntry/DesktopNavigationEntry.vue' /* webpackChunkName: "components/desktop-navigation-entry" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
