
import { isVueRoute } from "@utils";
import { PAGES } from "@consts";
import WfButton from "@/components/common/WfButton.vue";

const isBlog404 = (path) => path.includes("/blog/");

/**
 * This "layout" is special, and it will be embededed within the <Nuxt /> component in the default layout.
 * This is the intended way nuxt works with error pages. (shrug). This is the reason why we don't need to add
 * the rest of the elements to the html above, such header, footer... and also that's the reason why we need
 * an !important override in the css to make the layout according to specification, as we don't need another
 * column for that. Thanks nuxt, I guess, for making things easier for developers.
 *
 * More info about error pages here -> https://nuxtjs.org/docs/concepts/views/#error-page.
 */
export default {
	components: {
		WfButton,
	},
	layout(context) {
		return context.store.state.error.statusCode !== 404
			? "error_layout_generic"
			: "error_layout_404";
	},
	props: {
		error: {
			required: true,
			type: Object,
		},
	},
	head() {
		return {
			meta: [
				{
					name: "robots",
					content: "noindex",
				},
			],
		};
	},
	created() {
		this.alternativePageAriaLabelKey = isBlog404(this.$route.path)
			? "ErrorPageReadBlogButtonAriaLabel"
			: "ErrorPageSeeWordListsButtonAriaLabel";

		this.alternativePageButtonTextKey = isBlog404(this.$route.path)
			? "ErrorPageReadBlogButtonText"
			: "ErrorPageSeeWordListsButtonText";
	},
	methods: {
		goToHome() {
			// TODO remove isVueRoute check once we migrate the whole thing into nuxt
			const homeUrl = "/";
			if (isVueRoute(homeUrl)) {
				window.location.href = homeUrl;
				return;
			}

			this.$router.push(PAGES.HOME);
		},

		goToAlternativePage() {
			// TODO remove isVueRoute check once we migrate the whole thing into nuxt
			const blogUrl = "/blog/";
			const wordListsUrl = "/index/";
			const targetUrl = isBlog404(this.$route.path)
				? blogUrl
				: wordListsUrl;

			if (isVueRoute(targetUrl)) {
				window.location.href = targetUrl;
				return;
			}

			this.$router.push(
				isBlog404(this.$route.path)
					? PAGES.ARTICLE_INDEX
					: PAGES.WORDS_LISTS_INDEX
			);
		},
	},
};
