import { DICTIONARIES_IN_API, PAGES } from "@consts";

import {
	getDefaultDictionariesByPage,
	pageHasSpecificDictionary,
} from "@utils";

export default ({ route, store }) => {
	// Set specific default dictionaries if applicable.
	if (
		store.getters["navigation/isLandingPage"] &&
		pageHasSpecificDictionary(route.name)
	) {
		store.commit(
			"query/setDictionary",
			getDefaultDictionariesByPage(route.name)
		);
	}

	/**
	 * If it's a Scrabble results page and the
	 * selected dictionary isn't a Scrabble dictionary,
	 * change it.
	 */
	if ([PAGES.SCRABBLE_DICT_RESULTS].includes(route.name)) {
		const currentDictionary = store.getters["query/currentDictionary"];
		if (
			![DICTIONARIES_IN_API.US, DICTIONARIES_IN_API.UK].includes(
				currentDictionary.value
			)
		) {
			store.commit("query/setDictionary", DICTIONARIES_IN_API.US);
		}
	}
};
