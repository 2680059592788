/* eslint-disable no-console */
import {
	isVueRoute,
	isHybridEnvironment,
	isHybridLocalEnvironment,
} from "@utils";

export default (context) => {
	// `context.from` is only ever available on the client side, so this is automatically client-safe.
	const from = context.from || null;

	const isDebug =
		context.store.getters["features/isFeatureEnabled"]("DebugMode") &&
		context.store.getters["features/isFeatureEnabled"]("DebugPerformance");

	if (isDebug) {
		console.debug("0-nuxt. Inside the NUXT middleware ");
	}

	// If this page is not supposed to be served via Nuxt, trigger a hard refresh.
	if (from && isVueRoute(context.route.path)) {
		if (isHybridEnvironment(window.location.origin)) {
			if (isDebug) {
				console.debug(
					`1.1-nuxt. Moving to VUE at ${context.route.fullPath}`
				);
			}
			window.location.assign(context.route.fullPath);
			return false;
		} else if (isHybridLocalEnvironment(window.location.origin)) {
			if (isDebug) {
				console.debug(
					`1.2-nuxt. Moving to VUE at http://wordfinder-local.yourdictionary.com:8080${context.route.fullPath}`
				);
			}
			window.location.assign(
				`http://wordfinder-local.yourdictionary.com:8080${context.route.fullPath}`
			);
			return false;
		}
	}

	if (isDebug) {
		console.debug("2-nuxt. Remaining in the Nuxt context");
	}
};
