export const STORAGE_KEYS = {
	ID: "TBL_BUCKET_ID",
	CONFIG: "TBL_BUCKET_CONFIG",
	OVERRIDE: "TBL_BUCKET_CONFIG_OVERRIDE",
};

/**
 * Takes a bucket ID and returns a URL string.
 * @param {Number|String} bucketId The ID of the bucket
 */
export const getBucketURL = (bucketId) => {
	return `https://tbl.yourdictionary.com/wordfinder/buckets/current/${bucketId}.json`;
};

/**
 * Gets the bucket ID from local storage, and writes one
 * if one does not exist.
 *
 * @returns {Number} the bucket ID
 */
export const getBucketId = () => {
	if (!process.client) {
		return 1;
	}
	let bucketId = localStorage.getItem(STORAGE_KEYS.ID);
	if (typeof bucketId !== "string") {
		bucketId = Math.floor(Math.random() * 100) + 1;
		localStorage.setItem(STORAGE_KEYS.ID, bucketId);
	} else {
		bucketId = Number(bucketId);
	}

	return bucketId;
};

/**
 * Attempts to write a new bucket ID to the localstorage.
 * Note that when using this method, it is best to encourage
 * a browser refresh.
 * @param {number} bucketId
 * @returns {boolean} Whether or not the operation was successful
 */
export const setBucketId = (bucketId) => {
	if (!process.client) {
		return true;
	}
	// Fail safely if cookies not supported
	if (!navigator.cookieEnabled) {
		return true;
	}

	// Make sure the bucket ID is a valid value.
	if (typeof bucketId !== "number") {
		console.error(`Attempting to set an invalid bucket ID: ${bucketId}`);
		return false;
	}

	if (bucketId < 1 || bucketId > 100) {
		console.error(
			`Attempting to set bucket ID as an integer outside of allowed range: ${bucketId}`
		);
		return false;
	}

	localStorage.setItem(STORAGE_KEYS.ID, bucketId);
	return true;
};

/**
 * Attempts to read bucket config from local storage
 */
export const readBucketConfigFromLocalStorage = () => {
	const config = localStorage.getItem(STORAGE_KEYS.CONFIG);
	if (!config) {
		return null;
	}
	const match = config.match(/^(\d{8}_\d+)_(.*)$/);
	if (match === null) {
		return null;
	}
	return {
		configId: match[1],
		toggles: match[2].length ? match[2].split("|") : [],
	};
};

/**
 * Writes the given config to local storage as a formatted string,
 * and returns a formatted config object.
 * @param {String} configId The config ID
 * @param {Array} toggleArray The array of toggle strings
 */
export const writeBucketConfigToLocalStorage = (configId, toggleArray) => {
	const s = `${configId}_${toggleArray.join("|")}`;
	localStorage.setItem(STORAGE_KEYS.CONFIG, s);
	return {
		configId,
		toggles: toggleArray,
	};
};

export const getBucketConfigOverrides = () => {
	let overrides = [];

	if (!process.client) {
		return overrides;
	}

	const localStorageOverrides = localStorage?.getItem(STORAGE_KEYS.OVERRIDE);
	if (
		typeof localStorageOverrides === "string" &&
		localStorageOverrides.length
	) {
		overrides = localStorageOverrides
			.split("|")
			.map((override) => {
				const matchRegex = /^(\w+)=(0|1)$/;
				if (!matchRegex.test(override)) {
					return null;
				}

				const match = override.match(matchRegex);
				return {
					toggleKey: match[1],
					value: Number(match[2]),
					source: "localstorage",
				};
			})
			.filter((override) => override !== null);
	}

	// Load the overrides.
	const search = new URLSearchParams(window.location.search);
	for (const [newKey, newValue] of search.entries()) {
		if (/^\w+$/.test(newKey) && /^[0|1]$/.test(newValue)) {
			overrides.push({
				toggleKey: newKey,
				value: Number(newValue),
				source: "url",
			});
		}
	}

	return overrides;
};

export const writeBucketConfigOverridesToLocalStorage = (overrides = []) => {
	const s = overrides
		.filter((override) => {
			return (
				override.source === "localstorage" &&
				/^\w+$/.test(override.toggleKey) &&
				[0, 1].includes(override.value)
			);
		})
		.map((override) => `${override.toggleKey}=${String(override.value)}`)
		.join("|");

	localStorage.setItem(STORAGE_KEYS.OVERRIDE, s);
};

/**
 * Takes a toggle key string and checks if the toggle is enabled,
 * either via config or via override. Note that unlike the getter, this
 * does not check the VueX store, and is only meant to be used when the
 * getter cannot be used (although in theory it should almost always
 * return the same value).
 * @param {String} toggleKey The toggle key to check
 */
export const isFeatureEnabled = (toggleKey) => {
	if (!process.client) {
		return false;
	}

	const overrides = getBucketConfigOverrides();

	// The highest priority is the override, so check that first.
	const overrideValue = overrides.find(
		(override) => override.toggleKey === toggleKey
	);
	if (overrideValue !== undefined) {
		return overrideValue.value === 1;
	}

	const localConfig = readBucketConfigFromLocalStorage();
	if (localConfig === null) {
		return false;
	}

	// Next check if this key is among the toggles, and return the resulting boolean.
	return localConfig.toggles.includes(toggleKey);
};
