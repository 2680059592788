
import Breadcrumb from "@lovetoknow/cms-breadcrumb";
import { isEmpty, getCombinedWLPageQueryParam } from "@utils";
import { mapGetters } from "vuex";

export default {
	name: "TheBreadcrumb",
	components: {
		Breadcrumb,
	},
	props: {
		items: {
			type: Array,
			required: true,
		},
	},
	head() {
		return {
			script: [
				this.$breadcrumbs.createBreadcrumbSchemaScriptEntry(
					this.schemaMappedItems
				),
			],
		};
	},
	computed: {
		...mapGetters("navigation", [
			"isWordsByLengthResultsPage",
			"isWordsWithResultsPage",
			"isWordsStartingResultsPage",
			"isWordListResultsPage",
			"isWordListCombinationResultsPage",
		]),
		wordlistBreadcrumbEntry() {
			if (this.isWordsByLengthResultsPage) {
				return {
					label: `${this.$route.params.length} letter words`,
					url: this.$route.path,
				};
			}

			let letterParam = this.$route.params.letter || "";
			if (this.isWordsWithResultsPage) {
				/**
				 * @todo this regex and replacement is used here, in the set-dictionary middleware, and probably in the results page component as well. A vuex getter might be a good idea for this, but we'd have to get the route params into the store first. An alternative is a mixin. Worth a discussion.
				 */
				letterParam = letterParam.replace(/^(\w+)-and-(\w+)$/, "$1$2");
				return {
					label: `words with the ${
						letterParam.length > 1 ? "letters" : "letter"
					} ${letterParam} `,
					url: this.$route.path,
				};
			}

			if (this.isWordsStartingResultsPage) {
				return {
					label: `words that start with ${
						letterParam.length > 1 ? "letters" : "letter"
					} ${letterParam} `,
					url: this.$route.path,
				};
			}

			return {
				label: `words ending in ${
					letterParam.length > 1 ? "letters" : "letter"
				} ${letterParam} `,
				url: this.$route.path,
			};
		},
		combinedWordlistBreadcrumbEntry() {
			const startsParam = getCombinedWLPageQueryParam(
				this.$route.params.advanced,
				"starts"
			);
			const withParam = getCombinedWLPageQueryParam(
				this.$route.params.advanced,
				"with"
			);
			const endsParam = getCombinedWLPageQueryParam(
				this.$route.params.advanced,
				"ends"
			);
			const lengthParam = getCombinedWLPageQueryParam(
				this.$route.params.advanced
			);

			const lengthWordsPart = !isEmpty(lengthParam)
				? `${lengthParam} letter words`
				: "words";
			const startWordsPart = !isEmpty(startsParam)
				? `starting with ${startsParam}`
				: "";
			const withWordsPart = !isEmpty(withParam)
				? `containing ${withParam}`
				: "";
			const endWordsPart = !isEmpty(endsParam)
				? `ending in ${endsParam}`
				: "";

			const numberOfLetterVariables = [
				startsParam,
				withParam,
				endsParam,
			].filter((variable) => !isEmpty(variable)).length;

			let firstCombinator = "";
			let secondCombinator = "";
			if (numberOfLetterVariables === 2 && startsParam) {
				firstCombinator = " and";
			} else if (numberOfLetterVariables === 2 && withParam) {
				secondCombinator = " and";
			} else if (numberOfLetterVariables === 3) {
				firstCombinator = ",";
				secondCombinator = " and";
			}

			return {
				label: `${lengthWordsPart} ${startWordsPart}${firstCombinator} ${withWordsPart}${secondCombinator} ${endWordsPart}`?.replace(
					/\s+/g,
					" "
				),
				url: this.$route.path,
			};
		},
		nuxtLinkedItems() {
			return this.items.map(this.$breadcrumbs.mapMetaBreadcrumbItem);
		},
		mappedItems() {
			if (isEmpty(this.items)) {
				return [];
			}

			const homeBreadcrumbItem =
				this.$breadcrumbs.getHomeBreadcrumbItem();

			const breadcrumbItems = this.nuxtLinkedItems;

			const baseItems = [homeBreadcrumbItem, ...breadcrumbItems];

			/**
			 * @todo use the word-lists mixin to manage this.
			 */
			if (this.isWordListResultsPage) {
				const wlResultsItem = this.wordlistBreadcrumbEntry;

				return [...baseItems, wlResultsItem];
			}

			if (this.isWordListCombinationResultsPage) {
				const wlResultsItem = this.combinedWordlistBreadcrumbEntry;

				return [...baseItems, wlResultsItem];
			}

			return baseItems;
		},
		// This computed returns the breadcrumb schema entries. Is based on the computed mappedItems,
		// but unlike that list, it has the last entry as a URL path too
		schemaMappedItems() {
			const breadcrumbItems = [...this.mappedItems];
			const lastBreadcrumb = breadcrumbItems.pop();
			if (lastBreadcrumb) {
				breadcrumbItems.push({
					...lastBreadcrumb,
					url: this.$route.path,
				});
			}
			return breadcrumbItems;
		},
	},
};
