export const ARTICLE_TYPES = {
	LEAD: "lead",
	LATEST: "latest",
	REMAINING: "remaining",
};

export const AUTHORS = {
	"155555-michael-kwan": "Michael Kwan",
	"156868-matt-salter": "Matt Salter",
};
