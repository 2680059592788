import { DICTIONARIES, DICTIONARY_LISTS } from "@consts";
import {
	writeDictionary,
	getDictionaryByValue,
	getDefaultDictionariesByPage,
	isEmpty,
} from "@utils";

export const state = () => ({
	tiles: "",
	/**
	 * @todo I'm pretty sure at this point that this state property only exists to track the localstorage value. To avoid confusion, it should be named something else.
	 */
	dictionary: "",
	bonuspoints: false,
	sortResults: null,
	/**
	 * This object saves the settings and results from the last
	 * results API call. We keep them so that we can still show old
	 * results in the loading screen while querying new results, even
	 * when the route has changed.
	 *
	 * @note While `dictionary` is the saved equivalent of the
	 * `currentDictionary` getter value (with the former being
	 * mapped as `savedDictionary`), the other properties are in
	 * fact mapped as computed properties and used as primary data
	 * sources. This is because the dictionary is the only "setting"
	 * that we need to show simultaneously in both its new and old value.
	 * Everything else is essentially just a data property moved into
	 * the store so it doesn't get lost when the Results page component
	 * gets destroyed and recreated.
	 *
	 * Still annoyed that Nuxt does that :\
	 */
	cache: {
		anagrams: {},
		dictionary: {},
		results: {},
		match: {},
		totalResultsCount: 0,
		totalAlternativeResultsCount: 0,
		cmsHeading: "",
		cmsResponse: {},
		interlinks: {},
		suggestedLinks: {},
		alternativeResults: {},
		alternativeTiles: "",
	},
});

export const getters = {
	currentDictionary: (state, getters, rootState, rootGetters) => {
		const isLandingPage = rootGetters["navigation/isLandingPage"];
		const isScrabblePage = rootGetters["navigation/isAnyScrabblePage"];
		const dictionaryList = isScrabblePage
			? DICTIONARY_LISTS.SCRABBLE_ONLY
			: DICTIONARY_LISTS.DEFAULT;

		// First check the URL query, and return if a valid value is found.
		let queryDictionary =
			rootState?.navigation?.currentRouteQuery?.dictionary;

		// In case there are multiple values, use the first one.
		if (Array.isArray(queryDictionary)) {
			queryDictionary = queryDictionary[0];
		}

		if (!isEmpty(queryDictionary)) {
			queryDictionary = getDictionaryByValue(
				dictionaryList,
				queryDictionary
			);
			if (!isEmpty(queryDictionary)) {
				return queryDictionary;
			}
		}

		// Then for the landing pages, check the localstorage value (which is tracked in this store module as the `dictionary` state value) and return if a valid value is found.

		// But before that, we make sure that this isn't a page with a dedicated dictionary. If that is the case, then we'd want to ignore whatever localstorage dictionary is present.
		const pageHasDedicatedDictionary =
			rootGetters["navigation/pageHasDedicatedDictionary"];

		if (
			isLandingPage &&
			!isEmpty(state.dictionary) &&
			!pageHasDedicatedDictionary
		) {
			const stateDictionary = getDictionaryByValue(
				dictionaryList,
				state.dictionary
			);
			if (!isEmpty(stateDictionary)) {
				return stateDictionary;
			}
		}

		// Finally, return the default dictionary for this route name.
		return getDictionaryByValue(
			dictionaryList,
			getDefaultDictionariesByPage(
				rootState?.navigation?.currentRouteName
			)
		);
	},
	alternativeWildcardsCount: (state) => {
		return (state.cache.alternativeTiles.match(/_/g) || []).length;
	},
};

export const mutations = {
	setDictionary(state, value) {
		// Make sure dictionary value is valid.
		const isValidDictionary = Object.values(DICTIONARIES).includes(value);
		if (!isValidDictionary) {
			return;
		}
		state.dictionary = value;
		writeDictionary([{ dict: state.dictionary }]);
	},
	loadDictionary(state, value) {
		state.dictionary = value;
	},
	saveSearch(state, cachePayload) {
		state.cache = {
			...state.cache,
			...cachePayload,
		};
	},
	clearSavedSearch(state) {
		state.cache = {
			dictionary: {},
			results: {},
			match: {},
			totalResultsCount: 0,
			totalAlternativeResultsCount: 0,
			cmsHeading: "",
			cmsResponse: {},
			interlinks: {},
			suggestedLinks: {},
			alternativeResults: {},
			alternativeTiles: "",
		};
	},
};
