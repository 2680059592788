import { DICTIONARIES_IN_API } from "@consts";

/**
 * Returns the position on the Y coord for all the values given, based on game icons sprite meta data given
 *
 * @param {Array<String>} dictionaryList List of dictionaries in a stringy fashion
 * @param {{ offsetY: Number, iconHeight: Number, position: { [key: String], Number } }} spriteMeta An object representing game icons sprite meta data for an individual tile in a vertical sprite, with an object representing positions for each of the dictionary values
 * @return {{ [givenValue: String]: Number }} Position of each of the tiles in the sprite.
 */
export const getSpriteBackgroundYPositions = (dictionaryList, spriteMeta) =>
	dictionaryList.reduce((acc, curr) => {
		const id = getDictionaryApiInternalKeyFromValue(curr) || curr;
		const multiplier = spriteMeta.position[id] || 1;
		const backgroundPosition = spriteMeta.iconHeight * multiplier;
		const backgroundPositionCorrection =
			spriteMeta.offsetY - spriteMeta.iconHeight; // remove the equivalent of the height to frame it correctly
		const position = backgroundPosition + backgroundPositionCorrection;
		acc[curr] = position === 0 ? position : position * -1; // negates to make the Y axis go negative

		return acc;
	}, {});

/**
 * Retrieves the corresponding key in DICTIONARIES_IN_API for a given value
 * This tries to use our internal keys defined in DICTIONARIES_IN_API. This way,
 * in case an endpoint changes the dictionary value parameter we need to send,
 * we wont need to change anything on our template, style classes, or dictionary keys.
 *
 * @param {String} value Value corresponding the key we want to retrieve
 * @returns {String} Key for the value specified
 */
export const getDictionaryApiInternalKeyFromValue = (value) => {
	return Object.keys(DICTIONARIES_IN_API).find(
		(key) => DICTIONARIES_IN_API[key] === value
	);
};

/**
 * Checks if the score field is applicable based on the dictionary value
 *
 * @param {String} value
 * @returns {Boolean}
 */
export const hasDictionaryScoreField = (value) =>
	![
		DICTIONARIES_IN_API.ANAGRAM,
		DICTIONARIES_IN_API.PICS,
		DICTIONARIES_IN_API.JUM,
		DICTIONARIES_IN_API.CONNECT,
		DICTIONARIES_IN_API.COOKIES,
		DICTIONARIES_IN_API.TWIST,
		DICTIONARIES_IN_API.CROSS,
		DICTIONARIES_IN_API.BRAIN,
		DICTIONARIES_IN_API.STORY,
		DICTIONARIES_IN_API.SWIPE,
		DICTIONARIES_IN_API.ALL,
		DICTIONARIES_IN_API.WORDLE,
		DICTIONARIES_IN_API.WORDLE_ALL,
		DICTIONARIES_IN_API.WS,
	].includes(value);
