import { onCLS, onFID, onINP, onLCP } from "web-vitals";
import nodeConfig from "../package.json";

export default ({ app, store }) => {
	// True if we wanna see debug logs. False otherwise.
	const debugLogs =
		store.getters["features/isFeatureEnabled"]("DebugPerformance");

	const DEFAULT_VITALS_SOFT = {
		LCP: "0.00", // total LCP score
		CLS: "0.00", // total CLS score
		INP: "0.00", // total INP score
		FID: "0.00", // total FID score
		clsCounter: 0, // the number of times a layout shift happened
		inpCounter: 0, // the number of times a delay till the next frame paint happened
		url: window.location.href, // the URL of the page the user initially landed in the app
		useragent: navigator.userAgent, // the user agent
		entryWidth: store.state.window.windowWidth, // the window width when the user first time loaded the app
		entryHeight: store.state.window.windowHeight, // the window height when the user first time loaded the app
		exitWidth: 0, // the window width when the user first time left the app
		exitHeight: 0, // the window height when the user first time left the app
		version: nodeConfig.version, // the current build version
	};

	// Number of decimals we want to see in each number
	const decimalNumber = 2;

	//  Function that returns a String representation of a given number, with a fixed number digits after the floating point
	const roundNumber = (num, decimalNumber) => {
		return num.toFixed(decimalNumber);
	};

	// Make sure not to destroy anything in the datalayer
	window.dataLayer = window.dataLayer || [];

	// This object will store all CWV events for the current session. It will get reset with each initial load of the website.
	window.webVitals = { ...DEFAULT_VITALS_SOFT };

	// When one of the CWV performance events fires, this callback function handles that event and stores it inside the datalayer object
	const onCWVChange = ({ name, value }) => {
		// If the library detects a layout shift
		if (name === "CLS") {
			// Increment the number of layout shifts that happened
			window.webVitals.clsCounter += 1;
		}

		// If the library detects a delay in the next paint
		if (name === "INP") {
			// Increment the count of INP detected
			window.webVitals.inpCounter += 1;
		}

		// If we want to see the debug logs in the console
		if (debugLogs) {
			console.log("CWV Tracker >> event detected", name, value);
		}

		// Every time a new event happens store the new value
		window.webVitals[name] = roundNumber(value, decimalNumber);
	};

	// When we want to report this session's CWV metrics this callback function is executed
	const pushFinalReport = () => {
		window.webVitals.exitWidth = store.state.window.windowWidth;
		window.webVitals.exitHeight = store.state.window.windowHeight;

		// Only send the data of users that still have this flag enabled and have waited at least for the content to load
		if (window.webVitals.LCP !== "0.00") {
			// Send the CWV object to GTM
			window.dataLayer.push({
				event: "push_core_web_vitals_soft",
				cwv: window.webVitals,
			});

			// If we want to see the debug logs in the console
			if (debugLogs) {
				console.log("CWV Tracker >> report", window.webVitals);
			}
		}

		// Reset the CWV object (for soft navigations)
		window.webVitals = { ...DEFAULT_VITALS_SOFT };
	};

	// If we want to track the CWV during this user session with soft navigations
	// If we want to see the debug logs in the console
	if (debugLogs) {
		console.log("CWV Tracker >> initialized (with soft navigations)");
	}

	// Start tracking all CWV
	onCLS(onCWVChange, { reportAllChanges: true, reportSoftNavs: true });
	onFID(onCWVChange, { reportAllChanges: true, reportSoftNavs: true });
	onINP(onCWVChange, { reportAllChanges: true, reportSoftNavs: true });
	onLCP(onCWVChange, { reportAllChanges: true, reportSoftNavs: true });

	// Every time the user navigates internally (to a new route within the SPA)
	app.router.afterEach((to, from) => {
		// The soft-navigation happens after the route has changed. So here we construct the URL from the old path
		window.webVitals.url = window.location.origin + from.fullPath;
	});

	// When the user closes the tab or the browser window report the CWV metrics collected during that session
	window.addEventListener("beforeunload", pushFinalReport);

	// Observe soft navigations and report the CWV metrics collected when it's detected
	const observer = new PerformanceObserver(pushFinalReport);
	observer.observe({ type: "soft-navigation", buffered: true });
};
