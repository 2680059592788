const WF_DOMAIN_REGEXP = /\/\/wordfinder\.yourdictionary\.com/;
const WF_FULL_DOMAIN_REGEXP = /https?:\/\/wordfinder\.yourdictionary\.com/;
/**
 * Checks wether the given element is a valid A element
 * @param {HTMLElement} element A given HTML element
 * @returns {Boolean}
 */
function isValidAnchorElement(element) {
	return (
		element?.tagName?.toUpperCase() === "A" &&
		Boolean(element?.getAttribute("href"))
	);
}

/**
 * Finds if the clicked element is contained within an anchor element
 *
 * @param {Event} event On click event
 * @returns {HTMLElement | undefined}
 */
export function findAnchorElementFromEvent(event) {
	return event
		.composedPath()
		.find((t) => t?.tagName?.toUpperCase?.() === "A");
}

/**
 * Checks wether the href attribute value on an <a> element is an internal link
 *
 * @param {HTMLElement} anchorElement <a> element
 * @returns {Boolean}
 */
export function isInternalAnchorElement(anchorElement) {
	if (!isValidAnchorElement(anchorElement)) {
		return false;
	}

	const href = anchorElement.getAttribute("href");
	return WF_DOMAIN_REGEXP.test(href) || /^\/[^/]/.test(href);
}

/**
 * Performs a history navigation taking the href value from the <a> element passed down
 *
 * @param {HTMLElement} anchorElement <a> element
 * @param {History} h History object, can come from this.$router.history as well
 */
export function forceHistoryPushOnAnchorElement(anchorElement, h) {
	if (!isInternalAnchorElement(anchorElement)) {
		return;
	}

	h.push(
		anchorElement.getAttribute("href").replace(WF_FULL_DOMAIN_REGEXP, "")
	);
}

/**
 * Opens a given <a> href in a new tab
 *
 * @param {HTMLElement} anchorElement <a> element
 * @param {Window} w Window object
 */
export function openExternalLinkInNewTab(anchorElement, w) {
	if (!isValidAnchorElement(anchorElement)) {
		return;
	}

	w.open(anchorElement.getAttribute("href"), "_blank", "noopener");
}
