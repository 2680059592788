export default ({ $aditude, store }) => {
	const getUserInteractionPromise = () => {
		return new Promise(function (resolve, reject) {
			[
				"click",
				"input",
				"keydown",
				"mousemove",
				"scroll",
				"touchmove",
			].forEach(function (e) {
				window?.addEventListener(
					e,
					function (ev) {
						resolve();
					},
					{ once: true }
				);
			});
		});
	};

	const getTimePromise = (milliseconds = 6000) => {
		return new Promise(function (resolve, reject) {
			setTimeout(() => {
				resolve();
			}, milliseconds);
		});
	};

	Promise.any([getUserInteractionPromise(), getTimePromise(3000)]).then(
		() => {
			const eventName = "libload-aditude";

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ event: eventName });
			if (
				!store?.getters["features/isFeatureEnabled"]?.(
					"DisableThirdParty"
				)
			) {
				$aditude.loadAditude();
			}
		}
	);
};
