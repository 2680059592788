var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"navigation__bg",class:{ 'navigation__bg--open': _vm.mobileMenuIsOpen },style:({ top: `${_vm.topIndent}px` }),on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.toggleMobileMenu.apply(null, arguments)}}},[_c('nav',{staticClass:"navigation",class:{ 'navigation--open': _vm.mobileMenuIsOpen }},[_c('ul',{staticClass:"nav-container"},[_vm._l((_vm.menuData),function(entry,ei){return _c('li',{key:ei},[_c(entry.useRouter ? 'HybridLink' : 'button',{tag:"component",staticClass:"nav-link",class:{
						'nav-link--is-new': entry.isNew,
						'nav-link--is-hot': entry.isHot,
					},attrs:{"href":entry.useRouter ? _vm.$t(entry.linkDictionaryKey) : null,"force-external":entry.useRouter &&
						typeof entry.forceExternal === 'boolean'
							? entry.forceExternal
							: null,"title":_vm.$t(entry.attrDictionaryKey),"exact":entry.useRouter,"type":entry.useRouter ? null : 'button'},on:{"click":function($event){return _vm.onButtonClick(entry)}},nativeOn:{"click":function($event){return _vm.onLinkClick(entry)}}},[_c('span',{staticClass:"nav-link__content",domProps:{"innerHTML":_vm._s(_vm.$t(entry.labelDictionaryKey))}}),_vm._v(" "),(entry.submenu)?_c('span',{staticClass:"nav-link__arrow"}):_vm._e()]),_vm._v(" "),(entry.submenu)?_c('nav',{staticClass:"navigation navigation--submenu",class:{ 'navigation--open': entry.submenuIsOpen }},[_c('ul',[_c('li',[_c('button',{staticClass:"nav-link nav-link--submenu nav-link--submenu-header",on:{"click":function($event){entry.submenuIsOpen = false}}},[_c('span',{staticClass:"nav-link__arrow nav-link__arrow--submenu"}),_vm._v(" "),_c('span',{staticClass:"nav-link__content",domProps:{"innerHTML":_vm._s(_vm.$t(entry.labelDictionaryKey))}})])]),_vm._v(" "),_vm._l((entry.submenu),function(item,i){return _c('li',{key:i},[_c('HybridLink',{staticClass:"nav-link nav-link--submenu",class:{
									'nav-link--is-new': item.isNew,
									'nav-link--is-hot': item.isHot,
								},attrs:{"href":_vm.$t(item.linkDictionaryKey),"title":_vm.$t(item.attrDictionaryKey)},nativeOn:{"click":function($event){return _vm.onLinkClick(item)}}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t(item.labelDictionaryKey))+"\n\t\t\t\t\t\t\t")])],1)})],2)]):_vm._e()],1)}),_vm._v(" "),_vm._l((_vm.externalSites),function(site,i){return _c('li',{key:`external-${i}`},[_c('Logo',{staticClass:"nav-link external-logo",attrs:{"logo-type":site.type,"label":site.label}})],1)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }