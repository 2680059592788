import { getBucketId } from "@/common/feature-toggling";

export const state = () => ({
	pageViewCounter: 1,
});

export const mutations = {
	UPDATE_PAGE_VIEW_COUNTER(state, value) {
		if (value) {
			state.pageViewCounter = value;
		} else {
			state.pageViewCounter++;
		}
	},
};

export const actions = {
	trackEvent(context, payload) {
		if (!process.client) {
			return false;
		}

		const { eventCategory, eventAction, eventLabel, eventValue, eventID } =
			payload;

		const eventData = {
			eventCategory,
			eventAction,
			eventLabel,
			eventValue,
			eventID,
		};

		const bucketId = getBucketId();
		if (bucketId) {
			eventData.metric1 = bucketId;
		}

		const toggles = context.rootState?.features?.toggles || [];
		if (toggles) {
			eventData.dimension4 = toggles.join(",");
		}

		const isFeatureEnabled =
			context.rootGetters?.["features/isFeatureEnabled"];

		const debuggingEnabled =
			typeof isFeatureEnabled === "function" &&
			isFeatureEnabled("DebugMode") &&
			isFeatureEnabled("DebugAnalytics");
		if (debuggingEnabled) {
			/* eslint-disable no-console */
			console.table({
				DEBUG: "WFW GA EVENT",
				...eventData,
			});
		}
	},
};
