export const fetchUserLocation = (axios) => {
	return axios
		.get(
			"https://pro.ip-api.com/json/?key=AmUN9xAaQALVYu6&fields=countryCode"
		)
		.then((response) => {
			const { data } = response;
			return data.countryCode;
		});
};
