import { mapActions, mapGetters, mapState } from "vuex";
import { SweetModal } from "sweet-modal-vue";
import google from "~/assets/images/socials/google.svg";

const modalContent = {
	title: "LoginDialogTitleSignUp",
	text: "LoginDialogText1SignUp",
	btnText: "LoginButtonTextSignUp",
	btnAriaLabel: "LoginButtonAriaLabelSignUp",
	btnTitle: "LoginButtonTitleAttributeSignUp",
	disclaimerText: "LoginDialogLegalDisclaimerSignUp",
	reminderText: "LoginDialogText3SignUp",
	eventDialogAction: "Click on header sign in button",
	eventDialogID: 39,
	eventLoginAction: "Click on Google sign in button",
	eventLoginID: 37,
	eventLabel: "LoginButtonTextHeaderSignIn",
};

export default {
	name: "LoginModal",
	components: {
		SweetModal,
	},
	data() {
		return {
			modalContent,
			googleLogo: google,
		};
	},
	computed: {
		...mapGetters("account", ["logInErrorMessage", "isLoggingIn"]),
		...mapState("account", ["isLogInDialogOpen"]),
	},
	methods: {
		...mapActions("account", ["OPEN_FIREBASE_POPUP", "CLOSE_LOGIN_DIALOG"]),
		toggleDialog(showDialog) {
			if (showDialog) {
				this.$refs.modal.open();
				this.onOpenDialog();
			} else {
				this.$refs.modal.close();
				this.onCloseDialog();
			}
		},
		onOpenDialog() {
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Login",
				eventAction: modalContent.eventDialogAction,
				eventLabel: modalContent.eventLabel,
				eventID: modalContent.eventDialogID,
			});
		},
		onRemindButtonClick() {
			this.CLOSE_LOGIN_DIALOG();
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Login",
				eventAction: "Click on Remind me later button",
				eventLabel: "LoginDialogText3SignUp",
				eventID: 41,
			});
		},
		onCloseDialog() {
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Login",
				eventAction: "Click on sign in cross icon",
				eventID: 43,
			});
			this.CLOSE_LOGIN_DIALOG();
		},
		onLogin() {
			this.OPEN_FIREBASE_POPUP();
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Login",
				eventAction: modalContent.eventLoginAction,
				eventLabel: modalContent.btnText,
				eventID: modalContent.eventLoginID,
			});
		},
		isDialogOpen() {
			return this.$store.state.account.isLogInDialogOpen;
		},
	},
	watch: {
		isLogInDialogOpen(value) {
			this.toggleDialog(value);
		},
	},
};
