export default ({ route, store }) => {
	// Subsequent navigations flag
	if (
		store.state.navigation.currentRouteName &&
		store.state.navigation.firstTimeAccess
	) {
		store.commit("navigation/setFirstTimeAccess", false);
	}

	// Set the current route name.
	store.commit("navigation/setCurrentRouteName", route.name);
	store.commit("navigation/setCurrentRouteQuery", route.query || {});
};
