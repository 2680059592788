export default {
	methods: {
		/**
		 * @param {string|string[]} routeName The name of the route to match. Can be a string to match against a single route, or an array of strings to match any one of them.
		 * @param {string} expectedComponent The name of the page component to match against.
		 * @returns {boolean} Whether or not the check was successful.
		 */
		pageCheck(routeName, expectedComponent) {
			const matched = this?.$route?.matched || [];
			const matchedComponentName =
				matched[matched.length - 1]?.components?.default?.options?.name;

			if (!matchedComponentName) {
				return false;
			}

			let routeMatched = false;
			const currentRouteName = this?.$route?.name || "";
			if (Array.isArray(routeName)) {
				routeMatched = routeName.includes(currentRouteName);
			} else if (typeof routeName === "string") {
				routeMatched = currentRouteName === routeName;
			}

			return routeMatched && expectedComponent === matchedComponentName;
		},
	},
};
