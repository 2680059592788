import { PAGES } from "@consts";
import { isTilesInBlacklist, alphabetizeTiles } from "@utils";

export default ({ app, store, route }) => {
	app.router.beforeEach((to, from, next) => {
		if (
			[PAGES.UNSCRAMBLER_RESULTS, PAGES.SCRABBLE_DICT_RESULTS].includes(
				to.name
			)
		) {
			const tiles = (to.params.tiles || "").toLowerCase();
			if (isTilesInBlacklist(tiles)) {
				const sortedTiles = alphabetizeTiles(tiles);

				if (tiles !== sortedTiles) {
					const parsedQs = Object.entries(to.query)
						.reduce((a, [key, value]) => {
							if (value) {
								a.push(`${key}=${value}`);
							}
							return a;
						}, [])
						.join("&");

					const path = app.router.resolve({
						name: to.name,
						params: { tiles: sortedTiles },
					});

					return next(
						`${path.href}/${parsedQs ? `?${parsedQs}` : ""}`
					);
				}
			}
		}
		next();
	});
};
