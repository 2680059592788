import { isResultsPage, getSearchParamsFromRoute, sortUrl } from "@utils";

export default ({ app, route, redirect }) => {
	if (!isResultsPage(route.name)) {
		return;
	}

	const params = getSearchParamsFromRoute(route);

	/**
	 * @note We do a double comparison here because if the basic string
	 * comparison matches, there's no need to do a string sorting.
	 * Efficient code is best code.
	 */
	if (
		params.raw.fullPath !== params.clean.fullPath &&
		sortUrl(params.raw.fullPath) !== sortUrl(params.clean.fullPath)
	) {
		redirect(params.clean.fullPath);
	}
};
