
export default {
	name: "DefaultLayout",
	head() {
		return {
			meta: [
				{
					"http-equiv": "origin-trial",
					content:
						"AuI7KBOOJcWs+C1djKzMmHfmwpGilOnVPRaniIov2mKKhgl9gJX0lXS/d9KNCnI+Iz4cqFKyHN9Qq6UZvxPA+wkAAACCeyJvcmlnaW4iOiJodHRwczovL3dvcmRmaW5kZXIueW91cmRpY3Rpb25hcnkuY29tOjQ0MyIsImZlYXR1cmUiOiJTb2Z0TmF2aWdhdGlvbkhldXJpc3RpY3MiLCJleHBpcnkiOjE3MTY5NDA3OTksImlzU3ViZG9tYWluIjp0cnVlfQ==",
				},
				{
					"http-equiv": "origin-trial",
					content:
						"Ajzt8oiRid9y5vICIMI8jta9SxN2xcXdvzBDLutJPPtdt0Xixy0axZrp4r3hhiVZY7P9Fr7uJQ8CmlCMd+ah3QoAAABzeyJvcmlnaW4iOiJodHRwczovL3dvcmRmaW5kZXItc3RnLnlvdXJkaWN0aW9uYXJ5LmNvbTo0NDMiLCJmZWF0dXJlIjoiU29mdE5hdmlnYXRpb25IZXVyaXN0aWNzIiwiZXhwaXJ5IjoxNzE2OTQwNzk5fQ==",
				},
				{
					"http-equiv": "origin-trial",
					content:
						"Ago2mb87QjVyC17qCQ7qoj2kZP2/WnnJuyYZf0EQNxsiB225at8iePhFqfVtGOawS7q6UFZKG47m+DT64Y1KcAsAAAByeyJvcmlnaW4iOiJodHRwczovL3dvcmRmaW5kZXItcWEueW91cmRpY3Rpb25hcnkuY29tOjQ0MyIsImZlYXR1cmUiOiJTb2Z0TmF2aWdhdGlvbkhldXJpc3RpY3MiLCJleHBpcnkiOjE3MTY5NDA3OTl9",
				},
			],
		};
	},
};
