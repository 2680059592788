import { getApp } from "@firebase/app";
import {
	getUserSubscriptionInYdr,
	subscribeUserInYdr,
} from "~/common/services/newsletter.service";

export const getFirebaseApp = async () => {
	const { getApps, getApp, initializeApp } = await import("firebase/app");
	const { getAuth } = await import("firebase/auth");
	let firebaseApp = null;
	const firebaseConfig = await import("@/config/firebaseConfig");

	if (!getApps().length) {
		firebaseApp = initializeApp(firebaseConfig.default);
	} else {
		firebaseApp = getApp();
	}

	const auth = getAuth();

	return auth;
};

export const logOut = async () => {
	const firebaseApp = await getFirebaseApp();
	await firebaseApp.signOut();
};

export const observeUserStateChanges = (callbackFunction) => {
	getFirebaseApp()
		.then((firebase) => {
			firebase.onAuthStateChanged((user) => {
				callbackFunction(user);
			});
		})
		.catch(console.error);
};

export const setPersistenceLevel = async () => {
	const firebaseApp = await getFirebaseApp();
	const { browserLocalPersistence, GoogleAuthProvider, signInWithPopup } =
		await import("firebase/auth");
	return firebaseApp
		.setPersistence(browserLocalPersistence)
		.then(() => {
			const provider = new GoogleAuthProvider();
			provider.addScope("profile");
			provider.addScope("email");

			return signInWithPopup(firebaseApp, provider);
		})
		.catch((error) => {
			throw error;
		});
};

export const fetchClaims = async () => {
	const firebaseApp = await getFirebaseApp();
	firebaseApp.currentUser.getIdTokenResult(true).then((idTokenResult) => {
		const hasUsPrivacy =
			idTokenResult.claims && idTokenResult.claims.usprivacy;
		return hasUsPrivacy;
	});
};

export const fetchUserPreferences = async (axios, config) => {
	const firebaseApp = await getFirebaseApp();
	return firebaseApp.currentUser.getIdToken().then((idToken) => {
		return axios
			.get(`${config.API_SERVICE_AUTH}/preferences`, {
				headers: { Authorization: `Bearer ${idToken}` },
			})
			.then((response) => {
				return response;
			})
			.catch((err) => {
				console.error(
					"Could not connect to the preferences endpoint",
					err
				);
			});
	});
};

export const updateNewsletterSubscription = async (axios, config, params) => {
	const firebaseApp = await getFirebaseApp();
	return firebaseApp.currentUser.getIdToken().then((idToken) => {
		subscribeUserInYdr(axios, config, params, idToken).catch((err) => {
			console.error("Could not connect to the newsletter endpoint", err);
		});
	});
};

export const getNewslettersSubscription = async (axios, config) => {
	const firebaseApp = await getFirebaseApp();
	return firebaseApp.currentUser.getIdToken().then((idToken) => {
		return getUserSubscriptionInYdr(axios, config, idToken).catch((err) => {
			console.error(err);
		});
	});
};

export const putUserPreferences = async (axios, config, preferences) => {
	const firebaseApp = await getFirebaseApp();
	return firebaseApp.currentUser.getIdToken().then((idToken) => {
		return axios
			.put(`${config.API_SERVICE_AUTH}/preferences`, preferences, {
				headers: { Authorization: `Bearer ${idToken}` },
			})
			.then(() => {})
			.catch((err) => {
				console.error(
					"Could not connect to the preferences endpoint",
					err
				);
			});
	});
};
