import { LARGE_DEVICE_WIDTH_MAX } from "@consts";

export const state = () => ({
	// Basic viewport properties.
	scrollY: 0,
	windowWidth: 0,
	windowHeight: 0,

	topAdHeight: 0,
	visibleHeaderHeight: 0,
	searchMobileHeaderHeight: 0,
});

export const getters = {
	headerClearanceHeight(state) {
		return state.topAdHeight + state.visibleHeaderHeight;
	},
	headerHeight(state, getters, rootState, rootGetters) {
		const isPersistentSearch = rootGetters["navigation/isPersistentSearch"];
		// Yes I know it looks weird doing a negative, but 50 should be treated as the default.
		const heightDifference = isPersistentSearch ? -8 : 0;
		return state.windowWidth <= LARGE_DEVICE_WIDTH_MAX
			? 50 + heightDifference
			: 68;
	},
};

export const mutations = {
	setWindowDimensions(state, payload) {
		state.windowHeight = payload.windowHeight;
		state.windowWidth = payload.windowWidth;
	},
	setWindowScroll(state, value) {
		state.scrollY = value;
	},
	setTopAdHeight(state, value) {
		state.topAdHeight = value;
	},
	setVisibleHeaderHeight(state, value) {
		state.visibleHeaderHeight = value;
	},
	SET_SEARCH_MOBILE_HEADER_HEIGHT(state, value) {
		state.searchMobileHeaderHeight = value;
	},
};
