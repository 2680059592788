
import UiButton from "@lovetoknow/ui-button";

export default {
	name: "WfButton",
	components: {
		UiButton,
	},
	props: {
		primary: {
			required: false,
			type: Boolean,
			default: false,
		},
		outlined: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
};
