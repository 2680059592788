
export default {
	name: "TheBurgerButton",
	computed: {
		isActive() {
			return this.$store.state.navigation.mobileMenuIsOpen;
		},
	},
	methods: {
		onBurgerClick() {
			this.$store.commit("navigation/toggleMobileMenu");
			if (this.isActive) {
				this.$store.dispatch("tracking/trackEvent", {
					eventCategory: "Header",
					eventAction: "Click on Mobile MENU button",
					eventLabel: "OpenMobileMenu",
				});
			}
		},
	},
};
