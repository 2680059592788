
import { isVueRoute, isHybridEnvironment } from "@utils";

export default {
	props: {
		href: {
			required: true,
			type: String,
		},
		exact: {
			required: false,
			type: Boolean,
			default: false,
		},
		forceExternal: {
			type: Boolean,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			isRegularAnchor: false,
		};
	},
	computed: {
		isExactActiveHybridLink() {
			return this.href === this.$route.path;
		},
	},
	created() {
		this.isRegularAnchor = this.forceExternal || false;
	},
	mounted() {
		if (typeof this.forceExternal === "boolean") {
			this.isRegularAnchor = this.forceExternal;
		} else {
			this.isRegularAnchor =
				isVueRoute(this.href) &&
				isHybridEnvironment(window?.location?.origin);
		}
	},
};
