import {
	SMALL_DEVICE_WIDTH_MAX,
	MEDIUM_PORTRAIT_WIDTH_MAX,
	MEDIUM_DEVICE_WIDTH_MAX,
	LARGE_DEVICE_WIDTH_MAX,
} from "@consts";

export default {
	computed: {
		isSmallDevice() {
			if (!process.client) {
				return true;
			}
			return (
				this.$store.state.window.windowWidth <= SMALL_DEVICE_WIDTH_MAX
			);
		},
		isMediumPortraitDevice() {
			if (!process.client) {
				return false;
			}
			return (
				this.$store.state.window.windowWidth <=
					MEDIUM_PORTRAIT_WIDTH_MAX &&
				this.$store.state.window.windowWidth > SMALL_DEVICE_WIDTH_MAX
			);
		},
		isMediumDevice() {
			if (!process.client) {
				return false;
			}
			return (
				this.$store.state.window.windowWidth <=
					MEDIUM_DEVICE_WIDTH_MAX &&
				this.$store.state.window.windowWidth > SMALL_DEVICE_WIDTH_MAX
			);
		},
		isSmallOrMediumPortraitDevice() {
			return this.isSmallDevice || this.isMediumPortraitDevice;
		},
		isSmallOrMediumDevice() {
			return this.isSmallDevice || this.isMediumDevice;
		},
		isLargeDevice() {
			return (
				!this.isSmallOrMediumDevice &&
				this.$store.state.window.windowWidth <= LARGE_DEVICE_WIDTH_MAX
			);
		},
		isExtraLargeDevice() {
			if (!process.client) {
				return false;
			}
			return (
				this.$store.state.window.windowWidth > LARGE_DEVICE_WIDTH_MAX
			);
		},
		isMediumDeviceOrWider() {
			if (!process.client) {
				return false;
			}

			return !this.isSmallDevice;
		},
		isMediumLandscapeDeviceOrWider() {
			return !this.isSmallOrMediumPortraitDevice;
		},
		isLargeDeviceOrWider() {
			return !this.isSmallOrMediumDevice;
		},
	},
};
