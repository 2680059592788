import { PRODUCTION_ORIGIN } from "@consts";
import { isVueRoute } from "@utils";

export default ({ app, store, i18n }, inject) => {
	app.router.beforeEach((to, from, next) => {
		if (from && from.name) {
			// Clear the breadcrumbs override on each navigation.
			store.commit("navigation/setBreadcrumbsOverride", []);
		}
		next();
	});

	const getHomeBreadcrumbItem = () => ({
		label: i18n.t("BreadcrumbHomeText"),
		url: "/",
		isNuxtLink: !isVueRoute("/"),
	});

	const mapMetaBreadcrumbItem = (item) => ({
		...item,
		label: i18n.t(item.label),
		isNuxtLink: !isVueRoute(item.url),
	});

	/**
	 * Creates a schema for breadcrumbs given a list of entries.
	 *
	 * @returns {@link https://schema.org/BreadcrumbList BreadcrumbList} A JSON-LD object representing a BreadcrumbList
	 */
	const createBreadcrumbSchema = (items) => ({
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: items.map((entry, index) => {
			return {
				"@type": "ListItem",
				position: index + 1,
				name: entry.label.trim(),
				item: `${PRODUCTION_ORIGIN}${entry.url}`,
			};
		}),
	});

	const createBreadcrumbSchemaScriptEntry = (items) => ({
		hid: "jsonld-breadcrumb",
		type: "application/ld+json",
		json: createBreadcrumbSchema(items),
	});

	inject("breadcrumbs", {
		getHomeBreadcrumbItem,
		mapMetaBreadcrumbItem,
		createBreadcrumbSchema,
		createBreadcrumbSchemaScriptEntry,
	});
};
