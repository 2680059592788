export default async (context) => {
	await context.store.dispatch("features/init");

	const toggles = context?.store?.state?.features?.toggles || [];
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({ tbl_toggles: toggles.join(",") });

	// Let's keep this around for about 6 months, so this should be removed after 17th October 2023.
	if (process.client && localStorage) {
		localStorage?.removeItem?.("WF_BUCKET_ID");
		localStorage?.removeItem?.("WF_BUCKET_CONFIG");
		localStorage?.removeItem?.("WF_BUCKET_CONFIG_OVERRIDE");
	}
};
