import { PAGES, LOGO_TYPES } from "@consts";

export default {
	name: "TheNewHeader",
	data() {
		return {
			currentWindowScroll: 0,
			currentHeaderScroll: 0,
			LOGO_TYPES,
		};
	},
	computed: {
		showBorder() {
			return !this.isPersistentSearch;
		},
		isPersistentSearch() {
			return this.$store.getters["navigation/isPersistentSearch"];
		},
		headerTopIndent() {
			// Since we're talking about pushing the header upwards, 'highestScroll' is an increasingly-negative number as the user scrolls downwards, and 'lowestScroll' is an increasingly-positive number as the user scrolls upwards.
			const newScrollY = this.scrollY;
			let newHeaderScroll = this.$store.state.window.topAdHeight;
			if (newScrollY > 0) {
				const delta = this.currentWindowScroll - newScrollY;
				newHeaderScroll = this.currentHeaderScroll + delta;

				if (newHeaderScroll < this.highestScroll) {
					newHeaderScroll = this.highestScroll;
				} else if (newHeaderScroll > this.lowestScroll) {
					newHeaderScroll = this.lowestScroll;
				}
			}
			if (this.mobileMenuIsOpen) {
				newHeaderScroll = this.$store.state.window.topAdHeight;
			}
			this.currentWindowScroll = newScrollY;
			this.currentHeaderScroll = newHeaderScroll;

			this.$store.commit(
				"window/setVisibleHeaderHeight",
				this.$store.getters["window/headerHeight"] +
					newHeaderScroll -
					this.$store.state.window.topAdHeight
			);

			return {
				top: `${this.currentHeaderScroll}px`,
			};
		},
		highestScroll() {
			return this.isSmallDevice
				? -this.$store.getters["window/headerHeight"]
				: this.$store.state.window.topAdHeight -
						this.$store.getters["window/headerHeight"];
		},
		lowestScroll() {
			return this.isSmallDevice
				? 0
				: this.$store.state.window.topAdHeight;
		},
		scrollY() {
			return this.$store.state.window.scrollY;
		},
		isAppPage() {
			return [PAGES.GET_THE_APP, PAGES.APP_ABOUT].includes(
				this.$route.name
			);
		},
		mobileMenuIsOpen() {
			return this.$store.state.navigation.mobileMenuIsOpen;
		},
		desktopSubMenuIsOpen() {
			return this.$store.state.navigation.desktopSubMenuIsOpen;
		},
	},
};
