import { canonical } from "@utils";

export default (context, inject) => {
	/**
	 * Builds a meta object ready to be used on the head() function in components.
	 * Given a title and a description, it will build the additional opengraph meta tags.
	 * Also, an additional array of objects can be passed down to extend the object that
	 * will be returned to be used as metadata for the head() function.
	 *
	 * @param {{ title: string, description: string }} param
	 * @param {{ name: string, hid: string, content: string }[]} metaExtend
	 * @returns {{ title: string, link: string, meta: { name: string, hid: string, content: string }[] }}
	 */
	const buildMetaDataObject = ({ title, description }, metaExtend = []) => ({
		title,
		meta: [
			{
				name: "description",
				hid: "description",
				content: description,
			},
			{
				name: "og:title",
				hid: "og:title",
				content: title,
			},
			{
				name: "og:description",
				hid: "og:description",
				content: description,
			},
			{
				name: "og:url",
				hid: "og:url",
				content: canonical(context.route).href,
			},
			...metaExtend,
		],
		link: [canonical(context.route)],
	});

	inject("buildMetaDataObject", buildMetaDataObject);
};
