import { PAGES } from "@consts";

export default {
	name: "TheGetTheAppButton",
	computed: {
		isGetTheAppPage() {
			return this.$route.name === PAGES.GET_THE_APP;
		},
		appUrl() {
			return this.isGetTheAppPage
				? "https://wordfinder.onelink.me/NEup/82f509f9"
				: "https://wordfinder.onelink.me/NEup/18927699";
		},
	},
	methods: {
		onButtonClick() {
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "App CTA",
				eventAction: "Click mobile button",
			});
		},
	},
};
