var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!!_vm.word)?_c('div',{staticClass:"report-word__overlay bg-white overflow-hidden inset-0 fixed z-super-low md:flex md:items-center md:justify-center",on:{"click":function($event){$event.preventDefault();if($event.target !== $event.currentTarget)return null;return _vm.closePopup.apply(null, arguments)}}},[(_vm.isSmallDevice)?_c('header',{staticClass:"report-word__header"},[(!_vm.isReported)?_c('button',{staticClass:"report-word__cancel",on:{"click":function($event){return _vm.closePopup($event, 'cancel')}}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t("ReportWordDialogCancelButton"))+"\n\t\t")]):_vm._e(),_vm._v("\n\t\t"+_vm._s(_vm.$t("ReportWordDialogTitleText"))+"\n\t")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"report-word p-5 md:relative md:bg-white md:rounded-6"},[(_vm.isMediumDeviceOrWider)?_c('button',{staticClass:"report-word__close cursor-pointer absolute top-0 right-0 h-11 w-11 bg-transparent border-none flex items-center justify-center text-base-desktop",on:{"click":_vm.closePopup}},[_c('span',{staticClass:"wficon-close"})]):_vm._e(),_vm._v(" "),_c('section',{staticClass:"content",class:{
				'content--reported text-center mx-auto md:text-left':
					_vm.isReported,
			}},[(!_vm.isReported)?[_c('h4',{staticClass:"content__header"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t("ReportWordDialogUserReportedWordTitleText"))+"\n\t\t\t\t")]),_vm._v(" "),_c('p',{staticClass:"content__value"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.word)+"\n\t\t\t\t")]),_vm._v(" "),_c('h4',{staticClass:"content__header"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t("ReportWordDialogSelectGameTitleText"))+"\n\t\t\t\t")]),_vm._v(" "),_c('p',{staticClass:"content__value content__value--game flex items-center justify-start"},[_c('span',{staticClass:"image rounded-6 mr-[10px] relative",style:({
							'background-position-y': `${
								_vm.dictSpriteCoord[_vm.dictionary.value]
							}px`,
						}),attrs:{"role":"img","aria-label":_vm.$t(_vm.dictionary.ariaLabel)}},[(_vm.isAScrabbleDictionary)?_c('span',{staticClass:"image-icon absolute",class:`image-icon-${_vm.dictionary.id.toLowerCase()}`}):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t(_vm.dictionary.text)))])])]:[_c('h4',{staticClass:"content__header content__header--reported"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t("ReportWordDialogUserReportedThanksTitleText"))+"\n\t\t\t\t")]),_vm._v(" "),_c('p',{staticClass:"content__value content__value--reported"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t("ReportWordDialogUserReportedThanksText"))+"\n\t\t\t\t")])],_vm._v(" "),_c('footer',{staticClass:"content__footer mt-[26px] flex justify-center md:justify-end"},[(!_vm.isReported)?[(_vm.isMediumDeviceOrWider)?_c('button',{staticClass:"content__button",attrs:{"title":_vm.$t('ReportWordDialogCancelTitleAttribute')},on:{"click":function($event){return _vm.closePopup($event, 'cancel')}}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t("ReportWordDialogCancelButton"))+"\n\t\t\t\t\t")]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"content__button content__button--primary md:ml-[10px]",attrs:{"title":_vm.$t('ReportWordDialogReportTitleAttribute')},on:{"click":_vm.onReportButtonClick}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t("ReportWordDialogReportButton"))+"\n\t\t\t\t\t")])]:_c('button',{staticClass:"content__button content__button--primary",attrs:{"title":_vm.$t('ReportWordDialogCloseTitleAttribute')},on:{"click":_vm.closePopup}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t("ReportWordDialogCloseButton"))+"\n\t\t\t\t")])],2)],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }