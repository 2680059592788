/**
 * The stubs that, when combined with the
 * layout strings above, form the complete
 * ad units
 */
export const AD_LAYOUT_STUBS = {
	RIGHT_RAIL: "Right_Rail",
	ADHESION: "Adhesion",
	DT_IN_CONTENT: "Desktop_In_Content",
	TOP_LEADERBOARD: "Top_Leaderboard",
	MOB_IN_CONTENT: "Mobile_In_Content",
};

/**
 * Used for the "pagetype" key-value pair
 */
export const AD_PAGETYPES = {
	HOMEPAGE: "homepage",
	RESULTS: "results",
	POST: "post",
	WORDLE_BLOG: "Wordle Blog",
};

/**
 * Used for the "placement" key-value pair
 */
export const AD_PLACEMENTS = {
	DT_TOP: "dt_top",
	MOB_TOP: "mob_top",
	DT_RIGHT_1: "dt_right_1",
	DT_RIGHT_2: "dt_right_2",
	DT_RIGHT_3: "dt_right_3",
	DT_LEFT_1: "dt_left_1",
	DT_IN_CONTENT_1: "dt_in_content_1",
	DT_IN_CONTENT_2: "dt_in_content_2",
	DT_IN_CONTENT_3: "dt_in_content_3",
	DT_IN_CONTENT_4: "dt_in_content_4",
	DT_IN_CONTENT_5: "dt_in_content_5",
	DT_IN_CONTENT_6: "dt_in_content_6",
	DT_IN_CONTENT_7: "dt_in_content_7",
	DT_IN_CONTENT_8: "dt_in_content_8",
	DT_IN_CONTENT_9: "dt_in_content_9",
	DT_IN_CONTENT_10: "dt_in_content_10",
	MOB_IN_CONTENT_0: "mob_in_content_0",
	MOB_IN_CONTENT_1: "mob_in_content_1",
	MOB_IN_CONTENT_2: "mob_in_content_2",
	MOB_IN_CONTENT_3: "mob_in_content_3",
	MOB_IN_CONTENT_4: "mob_in_content_4",
	MOB_IN_CONTENT_5: "mob_in_content_5",
	MOB_IN_CONTENT_6: "mob_in_content_6",
	MOB_IN_CONTENT_7: "mob_in_content_7",
	MOB_IN_CONTENT_8: "mob_in_content_8",
	MOB_IN_CONTENT_9: "mob_in_content_9",
	MOB_IN_CONTENT_10: "mob_in_content_10",
	DT_IN_LIST_1: "dt_in_list_1",
	DT_IN_LIST_2: "dt_in_list_2",
	DT_IN_LIST_3: "dt_in_list_3",
	DT_IN_LIST_4: "dt_in_list_4",
	DT_IN_LIST_5: "dt_in_list_5",
	DT_IN_LIST_6: "dt_in_list_6",
	DT_IN_LIST_7: "dt_in_list_7",
	DT_IN_LIST_8: "dt_in_list_8",
	DT_IN_LIST_9: "dt_in_list_9",
	DT_IN_LIST_10: "dt_in_list_10",
	MOB_IN_LIST_1: "mob_in_list_1",
	MOB_IN_LIST_2: "mob_in_list_2",
	MOB_IN_LIST_3: "mob_in_list_3",
	MOB_IN_LIST_4: "mob_in_list_4",
	MOB_IN_LIST_5: "mob_in_list_5",
	MOB_IN_LIST_6: "mob_in_list_6",
	MOB_IN_LIST_7: "mob_in_list_7",
	MOB_IN_LIST_8: "mob_in_list_8",
	MOB_IN_LIST_9: "mob_in_list_9",
	MOB_IN_LIST_10: "mob_in_list_10",
	MOB_ADHESION: "mob_adhesion",
	DT_ADHESION: "dt_adhesion",
};

/**
 * Used for the "position" key-value pair
 */
export const AD_POSITIONS = {
	TOP: "top",
	RIGHT: "right",
	LEFT: "left",
	ADHESION: "adhesion",
	IN_CONTENT: "in_content",
};

export const ADITUDE_BASE_IDS = {
	ANCHOR: "pb-slot-anchor",
	IN_CONTENT_1: "pb-slot-incontent-1",
	IN_CONTENT_2: "pb-slot-incontent-2",
	IN_CONTENT_3: "pb-slot-incontent-3",
	IN_CONTENT_4: "pb-slot-incontent-4",
	RIGHT_1: "pb-slot-right-1",
	RIGHT_2: "pb-slot-right-2",
};
