/*
	This file overloads the datalayer.push function.
	Much like GTM is doing on their side, we are attaching a listener to the push function
	to have a communication channel back, from GTM to the client.
 */
export default ({ store }) => {
	// The code is executed only once and before the GTM library load
	window.dataLayer = window.dataLayer || [];
	const oldPushFunction = window.dataLayer.push;

	window.dataLayer.push = function () {
		// Whenever a new entry is added to the datalayer array, check if that entry signals adding the HTL library code.
		if (
			arguments[0]?.event === "consent-loaded" &&
			arguments[0]?.eventPayload === "load-exco"
		) {
			// Add the script tag for the EXCO ads.
			const fjs = document.getElementsByTagName("script")[0];
			const js = document.createElement("script");
			js.className = "exco-player";
			js.setAttribute("programmatic", "true"); // If programmatic control is enabled player won't start loading until api.init() is called
			const playerId =
				process.env.NUXT_ENV_STAGE === "prod"
					? "722bf882-df54-4b64-a54f-b86c95d9e200"
					: "e5d6defa-1679-4434-9a2c-e467f66697f3";
			js.onload = function () {
				store.commit("ads/SET_IS_EXCO_LOADED", true);
			};
			js.src = `//player.ex.co/player/${playerId}`;
			fjs.parentNode.insertBefore(js, fjs);
		}

		// Proceed calling the previous window.dataLayer.push reference as if nothing happened
		const prevArguments = [].slice.call(arguments, 0);
		return oldPushFunction.apply(window.dataLayer, prevArguments);
	};
};
