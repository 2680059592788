import { PAGES } from "@consts";

/**
 * @todo remove this method (and the whole file, and the import line from the index file). We're not using them.
 */

/**
 * Return true if the given route is or is under Scrabble Dictionary section
 * @param {String} routeName - A $route.name string
 * @return {Boolean} true if route is a results page; false otherwise.
 */
export const isScrabbleDictionaryPage = (routeName) => {
	/**
	 * @todo this is not checking for Scrabble Checker page. And we have a getter that does a better job. Use that instead.
	 */
	return (
		routeName === PAGES.SCRABBLE_DICT ||
		routeName === PAGES.SCRABBLE_DICT_RESULTS
	);
};
