/**
 * Removes the nth occurence of a char in a given String.
 * @param {String} tiles The tiles entered by user
 * @param {String} char The char who we look to remove
 * @param {Number} occurrence The occurence number to remove
 * @returns {String} The given input String with the char removed
 */
export const removeNthChar = (tiles, char, occurrence) => {
	return tiles.replace(
		new RegExp(`^(?:.*?[${char}]){${occurrence}}`),
		(substring) => substring.replace(new RegExp(`[${char}]$`), "")
	);
};

/**
 * Recursively remove a char from a given String until the count of that char is bellow a given threshold.
 *
 * @param {String} tiles The tiles entered by user
 * @param {String} char The char to remove if count is above threshold
 * @param {Number} maxOccurrence Threshold of the max allowed char occurrences.
 * @returns {String} The given input String with less than max char occurrence count.
 */
export const removeCharUntilMax = (tiles, char, maxOccurrence) => {
	if (maxOccurrence === 0) {
		return tiles.replace(new RegExp(`[${char}]`, "g"), "");
	}

	const count = (tiles.match(new RegExp(`[${char}]`, "g")) || []).length;
	if (count <= maxOccurrence) {
		return tiles;
	}

	return removeCharUntilMax(
		removeNthChar(tiles, char, count),
		char,
		maxOccurrence
	);
};

/**
 * Replace non-English Characters
 *
 * @param {String} str The input string
 * @returns {String} The given input string with invalid characters removed
 */
export const allowOnlyEnChar = (str) => {
	return str.replace(/[^a-zA-Z_?]/gi, "");
};

/**
 * Replace non-English Characters and replace space to "?"
 *
 * @param {String} str The input string
 * @returns {String} The given input string with invalid characters removed
 */
export const allowOnlyEnCharAndSpace = (str) => {
	return allowOnlyEnChar(str.replace(/\s/, "?"));
};

/**
 * Pluralize filter
 */
export const pluralize = (value, word) => (value === 1 ? word : `${word}s`);
