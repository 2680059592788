export const INTERLINK_TYPE = {
	STARTS: "starts",
	ENDS: "ends",
	WITH: "with",
	LENGTH: "length",

	WITH_IN_RESPONSE: "contains",
};

export const INTERLINK_SPECIAL_CASE = {
	FALLBACK: "FALLBACK",
	LENGTH_5: "LENGTH_5",
};
