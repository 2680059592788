import { SMALL_DEVICE_WIDTH_MAX } from "@consts";
import { isVueRoute, isHybridEnvironment } from "@utils";

export default (context, inject) => {
	const goToHash = (id) => {
		if (typeof id !== "string" || !id.length) {
			return;
		}

		// // todo: at the moment we dont have implemented anything for this specific case
		// // 		consider if this still relevant when we have something in place where
		// //		this id comes through parameters
		// Provide backwards compatibility for "#belowresults" anchors in the
		// CMS article body by mapping them to the new div id format.
		// if (id === "belowresults") {
		// 	id = this.belowResultsAnchor;
		// }

		const anchorElement = document.getElementById(id.replace("#", ""));
		const top = anchorElement?.getBoundingClientRect?.()?.top;
		if (!top) {
			return;
		}

		let gapHeight =
			(context.store.state.window.visibleHeaderHeight ?? 0) + 15;
		if (context.store.state.window.windowWidth <= SMALL_DEVICE_WIDTH_MAX) {
			gapHeight +=
				context.store.state.window.searchHeaderMobileHeight || 25; // if undefined, set a bit of space, and guard against possible nan values
		} else {
			gapHeight += context.store.state.window.topAdHeight;
		}

		window.scrollTo(0, window.scrollY + top - gapHeight);
	};

	/**
	 * Capture any click events, check if they're happening
	 * inside a body copy element, and convert them into
	 * router-navigation clicks if applicable.
	 */
	window.addEventListener("click", (e) => {
		let t = e?.target;
		if (!t) {
			return;
		}

		/**
		 * In the case of CMS links, it can sometimes have a <u> element inside the link, which would end up being the target. In which case, change the variable to point to it's direct parent instead.
		 */
		if (t?.parentNode?.tagName?.toUpperCase() === "A") {
			t = t.parentNode;
		}

		/**
		 * If it's not an anchor element with a href and
		 * opening in the same tab, stop.
		 */
		if (
			t.tagName?.toUpperCase() !== "A" ||
			!t.hasAttribute("href") ||
			t.getAttribute("target") === "_blank"
		) {
			return;
		}

		/**
		 * If the href has a # character and is pointing to the same page
		 * call function to scroll to the hash with the correct offsets.
		 */
		if (t.hash && context.route.path === t.pathname) {
			e.preventDefault();
			context.app.router.push({
				query: context.route.query,
				hash: t.hash,
			});
			setTimeout(() => {
				goToHash(t.hash);
			}, 0); // push to the end of the stack to ensure we go to the correct scroll position AFTER router push did its job
			return;
		}

		/**
		 * If its path doesn't include a div.body-copy element,
		 * stop.
		 */
		let paths = e.composedPath();
		paths = Array.isArray(paths) ? paths : [];
		const isBodyCopyChild = paths.some((el, i, arr) => {
			if (el?.tagName?.toUpperCase() !== "DIV") {
				return false;
			}

			const classes = Array.from(el?.classList?.values() || []);
			return (
				classes.includes("body-copy") ||
				classes.includes("article__body") ||
				classes.includes("about")
			);
		});
		if (!isBodyCopyChild) {
			return;
		}

		/**
		 * At this point, this is something we need to handle
		 */
		e.preventDefault();
		const href = t.getAttribute("href");

		/**
		 * If its an internal link, we treat it
		 * like a SPA navigation
		 */
		const absoluteRegex = /^https?:\/\/wordfinder.*?\.yourdictionary\.com/;
		const relativeRegex = /^\/[^/]|^\/$/;
		if (absoluteRegex.test(href) || relativeRegex.test(href)) {
			const relativePath = href.replace(absoluteRegex, "");

			pushZebraNavigation(relativePath);
			return;
		}

		/**
		 * If none of the previous conditions are fullfilled
		 * then we will open the link in a new tab
		 */
		window.open(href, "_blank").focus();
	});

	const pushZebraNavigation = (to) => {
		let toAsHref = to;
		if (typeof toAsHref !== "string") {
			toAsHref = context.app.router.resolve(to).href;
		}

		if (
			typeof window === "object" &&
			isVueRoute(toAsHref) &&
			isHybridEnvironment(window.location.origin)
		) {
			return window.location.assign(toAsHref);
		}

		const redirectedTo = to.replace("/ending-with/ing/", "/ending-in-ing/");
		context.app.router.push(redirectedTo);
	};

	/**
	 * @todo maybe this should be one plugin
	 * injection called "$navigation" instead?
	 */
	inject("goToHash", goToHash);
	inject("pushZebraNavigation", pushZebraNavigation);
};
