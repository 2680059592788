import {
	PAGES,
	AD_LAYOUT_STUBS,
	AD_PAGETYPES,
	AD_POSITIONS,
	AD_PLACEMENTS,
	ADITUDE_BASE_IDS,
} from "@consts";

/**
 * Takes a route name and returns a pagetype value.
 * @param {string} routeName The name of the route
 * @returns {string} The pagetype value
 */
export const getAdPageType = (routeName) => {
	switch (routeName) {
		case PAGES.ARTICLE_DETAIL:
			return AD_PAGETYPES.POST;

		case PAGES.SCRABBLE_DICT_RESULTS:
		case PAGES.UNSCRAMBLER_RESULTS:
		case PAGES.WORDS_BY_LENGTH_RESULTS:
		case PAGES.WORDS_CONSONANTS:
		case PAGES.WORDS_ENDING_ING:
		case PAGES.WORDS_ENDING_RESULTS:
		case PAGES.WORDS_HAVING_RESULTS:
		case PAGES.WORDS_STARTING_RESULTS:
		case PAGES.WORDS_VOWELS:
		case PAGES.WORDLE_RESULTS:
		case PAGES.QUORDLE_RESULTS:
		case PAGES.WORDS_WITH_Q_NO_U:
			return AD_PAGETYPES.RESULTS;
		case PAGES.WORDLE_ARTICLE_DETAIL:
			return AD_PAGETYPES.WORDLE_BLOG;

		default:
			return AD_PAGETYPES.HOMEPAGE;
	}
};

/**
 * Takes an ad layout stub and tries to auto-guess
 * a placement value for it.
 * @param {string} stub The ad layout stub
 * @returns {string?} A possible placement value, or null if one can't be found.
 */
export const getAdPlacement = (stub) => {
	switch (stub) {
		case AD_LAYOUT_STUBS.RIGHT_RAIL:
			return AD_PLACEMENTS.DT_RIGHT_1;
		case AD_LAYOUT_STUBS.ADHESION:
			return AD_PLACEMENTS.MOB_ADHESION;
		case AD_LAYOUT_STUBS.DT_IN_CONTENT:
			return AD_PLACEMENTS.DT_IN_CONTENT_1;
		case AD_LAYOUT_STUBS.TOP_LEADERBOARD:
			return AD_PLACEMENTS.DT_TOP;
		case AD_LAYOUT_STUBS.MOB_IN_CONTENT:
			return AD_PLACEMENTS.MOB_IN_CONTENT_1;
		default:
			return null;
	}
};

/**
 * Takes an ad placement value, and tries to regex-match
 * it to get the ad position
 * @param {string} placement The ad placement value
 * @returns {string?} The position value
 */
export const getAdPosition = (placement) => {
	for (const position of Object.values(AD_POSITIONS)) {
		const regex = RegExp(position, "i");
		if (regex.test(placement)) {
			return position;
		}
	}

	return null;
};

/**
 * Takes a route name and returns a unique identifier for the page.
 * This is specifically to be used as a keyvalue for the ads.
 * @param {string} routeName The name of the route
 * @returns {string} The unique identifier for the page route
 */
export const getPageKeyValue = (routeName) => {
	const index = {
		[PAGES.HOME]: "WFW Home",
		[PAGES.FOURPICS]: "4 Pics 1 Word",
		[PAGES.ANAGRAM]: "Anagram Solver",
		[PAGES.ARTICLE_INDEX]: "WFW Blog",
		[PAGES.ARTICLE_DETAIL]: "WFW Blog",
		[PAGES.WORD_SEARCH]: "WFW Blog",
		[PAGES.CODY_CROSS]: "Codycross answers",
		[PAGES.WORDS_CONSONANTS]: "Consonant Words",
		[PAGES.WORDS_ENDING_ING]: "Words Ending in ING",
		[PAGES.WORDS_ENDING_INDEX]: "Words ending in",
		[PAGES.WORDS_ENDING_RESULTS]: "Words ending in",
		[PAGES.GAME_CHEATS]: "All Games",
		[PAGES.WORDS_LISTS_INDEX]: "All Lists",
		[PAGES.JUMBLE_SOLVER]: "Jumble Solver",
		[PAGES.WORDS_BY_LENGTH_INDEX]: "Words by length",
		[PAGES.WORDS_BY_LENGTH_RESULTS]: "Words by length",
		[PAGES.WORDS_WITH_Q_NO_U]: "Word with Q not followed by U",
		[PAGES.SCRABBLE_CHECKER]: "Scrabble checker",
		[PAGES.SCRABBLE_CHEAT]: "Scrabble Cheat",
		[PAGES.SCRABBLE_DICT]: "Scrabble Dictionary",
		[PAGES.SCRABBLE_DICT_RESULTS]: "Scrabble Dictionary",
		[PAGES.TEXT_TWIST]: "Text twist",
		[PAGES.UNSCRAMBLE]: "Unscramble",
		[PAGES.UNSCRAMBLER_RESULTS]: "Unscramble",
		[PAGES.WORDS_VOWELS]: "Vowel Words",
		[PAGES.WORD_CHUMS_CHEAT]: "Word chums cheat",
		[PAGES.WORD_CONNECT]: "Word connect answers",
		[PAGES.WORDCOOKIES]: "Word Cookies",
		[PAGES.WORD_DESCRAMBLER]: "Word descrambler",
		[PAGES.WORD_MAKER]: "Word Maker",
		[PAGES.WORD_SWIPE_CHEATS]: "Word swipe cheats",
		[PAGES.WORDBRAIN_ANSWERS]: "Wordbrain answers",
		[PAGES.WORDFEUD]: "Wordfeud helper",
		[PAGES.WORD_STORY]: "Words story answers",
		[PAGES.WORDS_STARTING_INDEX]: "Words that start with",
		[PAGES.WORDS_STARTING_RESULTS]: "Words that start with",
		[PAGES.WWF]: "Words with friends",
		[PAGES.LETTERS_AND_BLANK]: "Words with letters blank",
		[PAGES.WORDS_HAVING_INDEX]: "Words with",
		[PAGES.WORDS_HAVING_RESULTS]: "Words with",
		[PAGES.RANDOM_WORD_GENERATOR]: "Random Word Generator",
		[PAGES.CROSSWORD_SOLVER]: "Crossword Solver",
		[PAGES.CONTEXTO]: "Contexto",
		[PAGES.WORD_WARS]: "Word Wars",
		[PAGES.WORDLE_HINTS]: "wordle-hints",
		[PAGES.WORDLE_ANSWERS]: "wordle-answers",
		[PAGES.NYT_CONNECTIONS]: "NYT Connections",
		[PAGES.NYT_CONNECTIONS_TODAY]: "NYT Connections",
		[PAGES.NYT_STRANDS]: "NYT Strands",
	};

	return typeof index[routeName] === "string" ? index[routeName] : routeName;
};

/**
 * Takes an ad placement value, and returns an
 * Aditude base div id. Defaults to `pb-slot-incontent-1`.
 * @param {string} placement The ad placement value
 * @returns {string?} The Aditude base div id
 */
export const getAditudeBaseId = (placement) => {
	switch (placement) {
		case AD_PLACEMENTS.MOB_ADHESION:
		case AD_PLACEMENTS.DT_ADHESION:
		case AD_PLACEMENTS.DT_TOP:
		case AD_PLACEMENTS.MOB_TOP:
			return ADITUDE_BASE_IDS.ANCHOR;

		case AD_PLACEMENTS.DT_IN_CONTENT_1:
		case AD_PLACEMENTS.MOB_IN_CONTENT_1:
			return ADITUDE_BASE_IDS.IN_CONTENT_1;

		case AD_PLACEMENTS.DT_IN_CONTENT_2:
		case AD_PLACEMENTS.MOB_IN_CONTENT_2:
			return ADITUDE_BASE_IDS.IN_CONTENT_2;

		case AD_PLACEMENTS.DT_RIGHT_1:
			return ADITUDE_BASE_IDS.RIGHT_1;

		case AD_PLACEMENTS.DT_RIGHT_2:
			return ADITUDE_BASE_IDS.RIGHT_2;

		case AD_PLACEMENTS.DT_RIGHT_3:
			return ADITUDE_BASE_IDS.IN_CONTENT_3;

		case AD_PLACEMENTS.DT_IN_CONTENT_3:
		case AD_PLACEMENTS.MOB_IN_CONTENT_3:
			return ADITUDE_BASE_IDS.IN_CONTENT_3;

		case AD_PLACEMENTS.DT_IN_CONTENT_4:
		case AD_PLACEMENTS.MOB_IN_CONTENT_4:
			return ADITUDE_BASE_IDS.IN_CONTENT_4;

		default:
			return ADITUDE_BASE_IDS.IN_CONTENT_1;
	}
};
