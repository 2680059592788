export default ({ app, store }) => {
	let shouldIncrementCounter = false;

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: "firePageView",
		page_view_params: {
			page_view_count: store.state.tracking.pageViewCounter,
		},
	});

	// Every time the user navigates internally (to a new route within the SPA)
	app.router.afterEach((to, from) => {
		// Initial loading should not bump the counter
		if (!shouldIncrementCounter) {
			shouldIncrementCounter = true;
			return;
		}

		store.commit("tracking/UPDATE_PAGE_VIEW_COUNTER");

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "firePageView",
			page_view_params: {
				page_view_count: store.state.tracking.pageViewCounter,
			},
		});
	});
};
