// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/account/dialog-background.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-dialog[data-v-2a058c29] .sweet-modal{background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position-x:right;background-position-y:calc(100% + 35px);background-size:670px;padding-bottom:10px}@media(min-width:768px){.login-dialog[data-v-2a058c29] .sweet-modal.is-alert .sweet-content{padding:35px}.login-dialog[data-v-2a058c29] .sweet-modal{background-position-y:bottom;background-size:contain;padding-bottom:30px}}.dialog-caption[data-v-2a058c29]{font-size:24px;font-weight:600;letter-spacing:0;line-height:34px;margin-bottom:20px;text-align:center;margin-top:0}@media(min-width:768px){.dialog-caption[data-v-2a058c29]{font-size:29px;line-height:54px;max-width:none}}.dialog-text[data-v-2a058c29]{color:#555;font-size:15px;letter-spacing:0;line-height:25px;margin:auto;max-width:360px;white-space:normal}.dialog-text[data-v-2a058c29] a{color:#1565c0;font-weight:600;text-decoration:underline}.dialog-error[data-v-2a058c29]{background-color:#d0021b;color:#fff;font-weight:700;padding:0 15px}.loading[data-v-2a058c29]{align-content:center;display:grid;height:125px;justify-content:center}.google-btn[data-v-2a058c29]{align-items:center;background-color:#3e82f7;border:1px solid #3e82f7;color:#fff;display:flex;height:40px;margin:30px auto;padding:0;width:186px}.inline-text[data-v-2a058c29]{color:#546e7a;display:block;font-size:12px;letter-spacing:0;line-height:20px;margin:15px 0 20px;text-align:center}.inline-text[data-v-2a058c29] a{color:#546e7a;text-decoration:underline}.google-logo-wrapper[data-v-2a058c29]{align-items:center;background:#fff;display:flex;height:38px;justify-content:center;margin:0 10px 0 0;width:40px}.link[data-v-2a058c29]{color:#1565c0;display:block;font-size:15px;line-height:25px;text-align:center;text-decoration:underline}.link[data-v-2a058c29],button[data-v-2a058c29]{font-weight:600}button[data-v-2a058c29]{cursor:pointer;font-size:14px;letter-spacing:-.19px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
