/**
 * Write the CCPA Privacy string into a cookie to be read by the API.
 * @param {string} value The value of the cookie to be written. Defaults to 1--- as per understood specs.
 */
export function writeCCPACookie(value = "1---") {
	const d = new Date();
	d.setTime(d.getTime() + 10 * 365 * 24 * 60 * 60 * 1000); // Make it roughly 10 years from now.
	const expires = `expires=${d.toUTCString()}`;
	document.cookie = `usprivacy=${value};${expires};path=/;Domain=.yourdictionary.com;SameSite=Lax;secure`;
}

/**
 * Read the CCPA Privacy string from the 'usprivacy' cookie.
 */
export function readCCPACookie() {
	let cookieValue = "";
	const cookieName = "usprivacy=";
	const cookieArray = document.cookie.split(";");
	for (let i = 0; i < cookieArray.length; i++) {
		let cookie = cookieArray[i];
		while (cookie.charAt(0) === " ") {
			cookie = cookie.substring(1);
		}
		if (cookie.indexOf(cookieName) === 0) {
			cookieValue = cookie.substring(cookieName.length, cookie.length);
			break;
		}
	}

	// If the cookie cannot be found or has an invalid value, default it back to 1---, the CCPA default privacy string value.
	if (!/^[1][nNyY-][nNyY-][nNyY-]$/.test(cookieValue)) {
		cookieValue = "1---";
	}

	return cookieValue;
}
