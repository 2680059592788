import { mapState, mapGetters } from "vuex";

import { getSpriteBackgroundYPositions } from "@utils";
import {
	DICTIONARY_LISTS,
	DICTIONARIES_IN_API,
	GAME_ICONS_SPRITE_META_SMALL_ICONS,
} from "@consts";
export default {
	name: "ReportWordDialog",
	data() {
		return {
			isReported: false,
		};
	},
	computed: {
		...mapState("report-word-dialog", ["word"]),
		...mapGetters({
			dictionary: "query/currentDictionary",
		}),
		isAScrabbleDictionary() {
			return [DICTIONARIES_IN_API.US, DICTIONARIES_IN_API.UK].includes(
				this.dictionary?.id
			);
		},
		dictSpriteCoord() {
			/**
			 * @todo this game image thingy is used in several parts around the site. I'm sure we can turn this into a reusable component.
			 */
			return getSpriteBackgroundYPositions(
				DICTIONARY_LISTS.DEFAULT,
				GAME_ICONS_SPRITE_META_SMALL_ICONS
			);
		},
	},
	methods: {
		closePopup(e, type = "close") {
			e.preventDefault();
			const gaEventData = {
				close: {
					eventAction:
						"Click on Close button within the report dialog",
					eventLabel: "ReportWordDialogCloseButton",
					eventID: 129,
				},
				cancel: {
					eventAction:
						"Click on Cancel button within the report dialog",
					eventLabel: "ReportWordDialogCancelButton",
					eventID: 128,
				},
			};

			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Word Row Menu",
				...gaEventData[type],
			});

			this.$store.commit("report-word-dialog/close");
			this.isReported = false;
		},
		async onReportButtonClick() {
			await this.$axios.post(
				`${this.$config.API_SERVICE}/v2/feedback/invalid-word`,
				{
					word: this.word,
					game: this.$t(this.dictionary?.text || ""),
					source: "web",
				}
			);
			this.isReported = true;

			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Word Row Menu",
				eventAction: "Click on Report button within the report dialog",
				eventLabel: "ReportWordDialogReportButton",
				eventID: 130,
			});
		},
	},
};
