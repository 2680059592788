
import ClickOutside from "vue-click-outside";
import { isVueRoute } from "@utils";

export default {
	name: "DesktopNavigationEntry",
	directives: {
		ClickOutside,
	},
	props: {
		entry: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			menuData: {},
		};
	},
	computed: {
		isInternalLink() {
			return this.entry.useRouter;
		},
		forceExternal() {
			if (
				this.entry.useRouter &&
				typeof this.entry.forceExternal === "boolean"
			) {
				return this.entry.forceExternal;
			}

			return null;
		},
	},
	created() {
		this.menuData = this.entry;
	},
	methods: {
		onLinkClick() {
			this.dispatchGaEvent();
		},
		onButtonClick() {
			if (typeof this.menuData.submenuIsOpen === "boolean") {
				this.menuData.submenuIsOpen = !this.menuData.submenuIsOpen;
			}
			this.dispatchGaEvent();
		},
		onNavItemClickOutside() {
			if (typeof this.menuData.submenuIsOpen === "boolean") {
				this.menuData.submenuIsOpen = false;
			}
		},
		onSubmenuLinkClick(item) {
			this.dispatchGaEvent(item);
			this.onNavItemClickOutside();
		},
		dispatchGaEvent(item = this.menuData) {
			const eventCategory = "Header";
			const eventLabel = item.labelDictionaryKey;
			const eventActionTranslation =
				item.gaEventAction || this.$t(item.labelDictionaryKey);
			const eventAction = `Click on ${eventActionTranslation} navigation element`;
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory,
				eventAction,
				eventLabel,
			});
		},
		isVueRoute(href) {
			return isVueRoute(href);
		},
	},
};
