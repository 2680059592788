export const state = () => ({
	isSubscribing: false,
	finishedSubscribing: false,
});

export const mutations = {
	USER_SUBSCRIBED(state) {
		state.finishedSubscribing = true;
		state.isSubscribing = false;
	},
	USER_SUBSCRIBING(state) {
		state.isSubscribing = true;
	},
	RESET(state) {
		state.isSubscribing = false;
	},
};
